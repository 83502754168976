import { useEffect } from "react";
import { Select } from "antd";

import { countries } from "../../data/countries";
import { contentTypes } from "../../data/contentTypes";
import { categories } from "../../data/categories";
import { subcategories } from "../../data/subcategories";
import { sponsors } from "../../data/sponsors";
import { sports } from "../../data/sports";

import { Controller, useFormContext } from "react-hook-form";

const binaryOptions = [
  {
    value: "yes",
    title: "Yes",
  },
  {
    value: "no",
    title: "No",
  },
];

function ContentDetailsBlock() {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const isSponsorshipLinked = watch("isSponsorshipLinked");
  const linkedSponsorship = watch("linkedSponsorship");
  const category = watch("category");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div>
              <h3 className="text-md font-semibold text-yellow-800">
                Content Details
              </h3>
              <div className="mb-3 mt-2 text-sm text-yellow-700">
                <p>
                  Please provide details on when and where this content will be
                  made available, along with how it can be categorized.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div className="col-span-full">
          <label
            htmlFor="country"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            In which country does this content take place?
          </label>
          <select
            {...register("country", { required: true })}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-300 sm:text-sm sm:leading-6"
            defaultValue=""
            placeholder="Select country"
          >
            <option
              value=""
              selected="selected"
              disabled="disabled"
              hidden="hidden"
            >
              Select country...
            </option>
            {countries.map((country) => (
              <option value={country.value}>{country.value}</option>
            ))}
          </select>

          {errors.country && (
            <p className="mt-2 text-sm text-red-600">Country is required</p>
          )}
        </div>

        <div className="col-span-full">
          <label
            htmlFor="city"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            In which city does this content take place?
          </label>
          <div className="mt-2">
            <input
              {...register("city", { required: true })}
              type="text"
              id="city"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
            />{" "}
            {errors.city && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                City is required
              </p>
            )}
          </div>
        </div>

        <div className="col-span-full">
          <label
            htmlFor="contentType"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Content Type
          </label>
          <select
            {...register("contentType", { required: true })}
            id="contentType"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-300 sm:text-sm sm:leading-6"
            defaultValue=""
            placeholder=""
          >
            <option
              value=""
              selected="selected"
              disabled="disabled"
              hidden="hidden"
            >
              Select type...
            </option>
            {contentTypes.map((contentType) => (
              <option value={contentType.value}>{contentType.label}</option>
            ))}
          </select>

          {errors.contentType && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Content type is required
            </p>
          )}
        </div>

        <div className="col-span-full">
          <label
            htmlFor="category"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Content Category
          </label>
          <select
            {...register("category", { required: true })}
            id="category"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-300 sm:text-sm sm:leading-6"
            defaultValue=""
            placeholder="Select category"
          >
            <option
              value=""
              selected="selected"
              disabled="disabled"
              hidden="hidden"
            >
              Select passion category...
            </option>
            {categories.map((category) => (
              <option value={category.value}>{category.label}</option>
            ))}
          </select>

          {errors.category && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Category is required
            </p>
          )}
        </div>

        {category === "Sports" && (
          <div className="col-span-full">
            <p className="text-sm text-gray-500">
              Select the specific sport relating to this content:
            </p>

            <select
              {...register("sports", { required: true })}
              id="sports"
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-300 sm:text-sm sm:leading-6"
              defaultValue=""
              placeholder=""
            >
              <option
                value=""
                selected="selected"
                disabled="disabled"
                hidden="hidden"
              >
                Select sport...
              </option>
              {sports.map((sport) => (
                <option value={sport.value}>{sport.label}</option>
              ))}
            </select>

            {errors.sports && (
              <p className="mt-2 text-sm text-red-600">
                A specific sport value is required
              </p>
            )}
          </div>
        )}

        <div className="col-span-full">
          <label
            htmlFor="specialProductType"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Special Product Types
          </label>
          <p className="text-sm text-gray-500 mb-2">Select all that apply</p>

          <fieldset className="mt-2">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div className="flex items-center">
                <input
                  {...register("ecoFriendly")}
                  type="checkbox"
                  key={"ecoFriendly"}
                  id={"ecoFriendly"}
                  className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                />
                <label
                  htmlFor={"ecoFriendly"}
                  className="ml-3 block text-sm text-gray-900 leading-6"
                >
                  <span className="font-medium">Eco-friendly Experience: </span>
                  <span className="font-normal text-gray-700">
                    From zero-waste meals to eco-friendly outdoor adventures,
                    these experiences celebrate our planet and inspire everyday
                    environmentally friendly practices.
                  </span>
                </label>
              </div>
            </div>
          </fieldset>

          <fieldset className="mt-2">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div className="flex items-center">
                <input
                  {...register("isEpic")}
                  type="checkbox"
                  key={"isEpic"}
                  id={"Epic"}
                  className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                />
                <label
                  htmlFor={"Epic"}
                  className="ml-3 block text-sm text-gray-900 leading-6"
                >
                  <span className="font-medium">Epic Experience: </span>
                  <span className="font-normal text-gray-700">
                    Rare and truly once-in-a-lifetime sponsorship property
                    (event) or brand ambassador-led experiences.
                  </span>
                </label>
              </div>
            </div>
          </fieldset>

          <fieldset className="mt-2">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div className="flex items-center">
                <input
                  {...register("isIconic")}
                  type="checkbox"
                  key={"isIconic"}
                  id={"Iconic"}
                  className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                />
                <label
                  htmlFor={"Iconic"}
                  className="ml-3 block text-sm text-gray-900 leading-6"
                >
                  <span className="font-medium">Iconic Experience: </span>
                  <span className="font-normal text-gray-700">
                    Well-established experiences that happen within an iconic,
                    high tourism value destination and involve a top tourist
                    attraction. Best of the best. E.g. Eiffel Tower - Paris.
                  </span>
                </label>
              </div>
            </div>
          </fieldset>

          <fieldset className="mt-2">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div className="flex items-center">
                <input
                  {...register("privilegedAccess")}
                  type="checkbox"
                  key={"privilegedAccess"}
                  id={"privilegedAccess"}
                  className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                />
                <label
                  htmlFor={"privilegedAccess"}
                  className="ml-3 block text-sm text-gray-900 leading-6"
                >
                  <span className="font-medium">Privileged Access: </span>
                  <span className="font-normal text-gray-700">
                    Access (including pre-sale and post-sale access) to
                    hard-to-get premier locations, event/shows, restaurants,
                    etc.
                  </span>
                </label>
              </div>
            </div>
          </fieldset>

          <fieldset className="mt-2">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              <div className="flex items-center">
                <input
                  {...register("specialTreat")}
                  type="checkbox"
                  key={"specialTreat"}
                  id={"specialTreat"}
                  className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                />
                <label
                  htmlFor={"specialTreat"}
                  className="ml-3 block text-sm text-gray-900 leading-6"
                >
                  <span className="font-medium">Special Treat: </span>
                  <span className="font-normal text-gray-700">
                    An always-on, value-add tied to experience provider that
                    makes the experience more enjoyable and pleasant (e.g.
                    complimentary drink, BOGO).
                  </span>
                </label>
              </div>
            </div>
          </fieldset>
        </div>

        <div className="col-span-full">
          <label
            htmlFor="city"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Subcategories
          </label>
          <p className="text-sm text-gray-500 mb-2">Select all that apply</p>

          <Controller
            name="subcategories"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                defaultValue={field.defaultValue}
                mode="multiple"
                allowClear="allowClear"
                style={{
                  width: "100%",
                }}
                size="large"
                placeholder="Select all subcategories that apply"
                onChange={(values) => field.onChange(values)}
                onBlur={() => field.onBlur()}
                options={subcategories}
              />
            )}
          />
        </div>

        <div className="col-span-full">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Is there alcohol associated with this event?
          </label>

          <fieldset className="mt-4">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {binaryOptions.map((option) => (
                <div className="flex items-center">
                  <input
                    {...register("alcoholAffiliated", { required: true })}
                    type="radio"
                    value={option.value}
                    id={"alcoholAffiliated_" + option.value}
                    className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                  />
                  <label
                    htmlFor={"alcoholAffiliated_" + option.value}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                  >
                    {option.title}
                  </label>
                </div>
              ))}
              {errors.alcoholAffiliated && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  Alcohol affiliation value is required
                </p>
              )}
            </div>
          </fieldset>
        </div>

        <div className="col-span-full">
          <label
            htmlFor="isSponsorshipLinked"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Is this content linked to a Mastercard sponsorship asset?
          </label>
          <p className="text-sm text-gray-500">e.g. World Class Events</p>

          <fieldset className="mt-4">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {binaryOptions.map((option) => (
                <div className="flex items-center">
                  <input
                    {...register("isSponsorshipLinked", { required: true })}
                    type="radio"
                    value={option.value}
                    id={"isSponsorshipLinked_" + option.value}
                    className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-400"
                  />
                  <label
                    htmlFor={"isSponsorshipLinked_" + option.value}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                  >
                    {option.title}
                  </label>
                </div>
              ))}

              {errors.isSponsorshipLinked && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  Sponsorships value is required
                </p>
              )}
            </div>
          </fieldset>
        </div>

        {isSponsorshipLinked === "yes" && (
          <div className="col-span-full">
            <p className="block text-sm font-medium leading-6 text-gray-900">
              Select the linked sponsorship asset:
            </p>

            <select
              {...register("linkedSponsorship", { required: true })}
              id="linkedSponsorship"
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-300 sm:text-sm sm:leading-6"
              defaultValue=""
              placeholder="Select sponsorship from list"
            >
              <option
                value=""
                selected="selected"
                disabled="disabled"
                hidden="hidden"
              >
                Select sponsorship...
              </option>
              {sponsors.map((sponsor) => (
                <option value={sponsor.value}>{sponsor.value}</option>
              ))}
            </select>

            {errors.linkedSponsorship && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                Linked sponsorship is required
              </p>
            )}
          </div>
        )}

        {isSponsorshipLinked === "yes" && linkedSponsorship === "Other" && (
          <div className="col-span-full">
            <p className="text-sm text-gray-500">
              Please specify the sponsorship below:
            </p>
            <input
              {...register("otherSponsorship", { required: true })}
              type="text"
              id="otherSponsorship"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
            />{" "}
            {errors.otherSponsorship && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                "Other" sponsorship value is required
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ContentDetailsBlock;

import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { supabaseUrl } from "../supabaseClient";
import { categoriesForSummaryPage } from "../data/categories";
import { contentTypesForSummaryPage } from "../data/contentTypes";
import { redemptionTypesForSummaryPage } from "../data/redemptionTypes";
import { generateStats } from "../pages/Content/ContentSummary";
import dayjs from "dayjs";

Font.register({
  family: "mc-mark-narrow-medium",
  src: "/fonts/mark/MarkForMCNarrowW00-Medium.ttf",
});

Font.register({
  family: "mc-mark-narrow-book",
  src: "/fonts/mark/MarkForMCNarrowW00-Book.ttf",
});

Font.register({
  family: "Open Sans",
  src: "/fonts/OpenSans-SemiBold.ttf",
});

// Colors used by the charts.
export const COLORS = ["#d22a2f", "#de3a2c", "#ea4928", "#f55823", "#ff671b"];

// These values are dynamically replaced.
const chartConfig = {
  type: "outlabeledPie",
  data: {
    labels: [
      "Arts and Culture",
      "Culinary",
      "Entertainment",
      "Shopping",
      "Sports",
      "Travel",
      "Health and Wellness",
    ],
    datasets: [
      {
        backgroundColor: [
          "#d22a2f",
          "#de3a2c",
          "#ea4928",
          "#f55823",
          "#ff671b",
          "#d22a2f",
          "#de3a2c",
        ],
        data: [1, 2, 3, 4, 5, 6, 7],
      },
    ],
  },
  options: {
    layout: {
      padding: {
        left: 50,
        right: 50,
        top: 50,
        bottom: 50,
      },
    },
    plugins: {
      legend: false,
      outlabels: {
        text: "%l: %p",
        color: "white",
        stretch: 35,
        borderRadius: 2,
        font: {
          resizable: true,
          minSize: 13,
          maxSize: 20,
        },
      },
    },
  },
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#f3f4f6",
    padding: 10,
    paddingLeft: 20,
    paddingTop: 20,
  },
  card: {
    flexDirection: "column",
    height: 260,
    // padding: 20,
    backgroundColor: "white",
    borderColor: "gray",
    borderWidth: 1,
    width: 185, // "23%", // Adjust based on required gaps
    marginBottom: 10,
    marginRight: "2%",
  },
  productImage: {
    width: "100%", // or set a specific width
    height: 100, // adjust height as necessary
    marginBottom: 6, // optional spacing
  },
  category: {
    fontSize: 10,
    fontFamily: "mc-mark-narrow-medium",
    color: "#ea580c",
    textTransform: "uppercase",
    paddingLeft: 5,
    paddingRight: 5,
  },
  title: {
    marginTop: 5,
    fontSize: 11,
    fontFamily: "Open Sans",
    color: "black",
    paddingLeft: 5,
    paddingRight: 5,
    maxHeight: 56,
  },
  locationContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    width: "100%",
    maxHeight: 24,
  },
  ptrContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  locationIcon: {
    marginLeft: 3,
    width: 12,
    height: 12,
    marginRight: 5,
  },
  location: {
    fontSize: 11,
    maxWidth: "85%", // Adjust this percentage based on results
    fontFamily: "mc-mark-narrow-medium",
    color: "#1F2937",
  },
  price: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  priceIcon: {
    marginLeft: 3,
    width: 12,
    height: 12,
    marginRight: 5,
  },
  priceText: {
    fontSize: 11,
    fontFamily: "mc-mark-narrow-medium",
    color: "#1F2937",
  },
  tag: {
    marginTop: 5,
    fontSize: 10,
    color: "#1F2937",
    fontFamily: "mc-mark-narrow-book",
    backgroundColor: "#FFD700", // approximated color for bg-yellow-50
    padding: 2,
    marginRight: 5,
  },
  tagContainer: {
    flexDirection: "row",
    position: "absolute",
    bottom: 7,
    marginLeft: 5,
  },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "gray",
  },
  logo: {
    position: "absolute",
    bottom: 0,
    right: 20,
    width: 100,
    height: 50,
  },
  internalLabel: {
    position: "absolute",
    bottom: 20,
    left: 20,
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "gray",
    width: 500,
  },
  headerLabel: {
    position: "absolute",
    top: 40,
    textAlign: "center",
    color: "gray",
    fontSize: 20,
    fontFamily: "mc-mark-narrow-medium",
    fontWeight: "bold",
  },
  passionCategoryContainer: {
    position: "relative",
  },
  iconContainer: {
    position: "absolute",
    right: 0, // Adjust this value if you need some spacing to the right
    flexDirection: "row",
  },
  icon: {
    marginRight: 4,
    width: 12,
    height: 12,
  },
  badge: {
    position: "absolute", // this will position the badge on top of the image
    backgroundColor: "rgba(254,252,232,0.9)",
    padding: 3,
    borderRadius: 3,
    bottom: 10,
    left: 5,
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "#854D0E",
    textTransform: "uppercase",
    textDecoration: "none",
  },
  endDateLabel: {
    position: "absolute", // this will position the badge on top of the image
    backgroundColor: "rgba(255,255,255,0.8)",
    padding: 3,
    borderRadius: 3,
    bottom: 10,
    right: 5,
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "#854D0E",
    textTransform: "uppercase",
    textDecoration: "none",
  },
  imageContainer: {
    position: "relative",
  },
});

const chartPageStyles = StyleSheet.create({
  page: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 30, // Add padding if needed
  },
  imageContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10, // Add some spacing between the images
    width: "50%", // each container takes up half the width of the page
    height: "80%", // we allocate 80% of the page's height to the image
  },
  imageTitle: {
    textAlign: "center",
    fontSize: 26,
    fontFamily: "mc-mark-narrow-medium",
    fontWeight: "bold",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    aspectRatio: 1, // ensure square shape
  },
});

const summaryStyles = StyleSheet.create({
  page: {
    backgroundColor: "#f3f4f6",
    padding: 30,
  },
  title: {
    fontSize: 32,
    fontFamily: "mc-mark-narrow-medium",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 40,
  },
  fullPageTitle: {
    fontSize: 32,
    fontFamily: "mc-mark-narrow-medium",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 200,
  },
  statsContainer: {
    marginTop: 100,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  statContainer: {
    flex: 1,
    justifyContent: "center",
    borderRightWidth: 1,
    borderRightColor: "#E0E0E0",
    paddingHorizontal: 8,
  },
  lastStatContainer: {
    borderRightWidth: 0, // Remove border for the last stat
  },
  statName: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "mc-mark-narrow-medium",
    color: "#C2410C",
  },
  statValue: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "mc-mark-narrow-medium",
  },
});

const coverStyles = StyleSheet.create({
  page: {
    flex: 1,
    alignItems: "center",
    padding: 30,
    backgroundColor: "#f3f4f6",
  },
  image: {
    height: 60,
    marginTop: 30,
  },
  title: {
    fontSize: 32,
    fontFamily: "mc-mark-narrow-medium",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 20,
  },
  date: {
    fontSize: 16,
    fontFamily: "mc-mark-narrow-medium",
    color: "#C2410C",
    textAlign: "center",
    marginBottom: 24,
  },
  filtersLabel: {
    fontSize: 14,
    fontFamily: "mc-mark-narrow-medium",
    // color: "#C2410C",
    textAlign: "center",
    marginBottom: 24,
  },
  filtersTitleLabel: {
    // marginTop: 5,
    fontSize: 16,
    fontFamily: "mc-mark-narrow-medium",
    fontWeight: "bold",
    // color: "#C2410C",
    textAlign: "center",
    marginBottom: 20,
  },
  keywordList: {
    fontFamily: "mc-mark-narrow-medium",
    fontSize: 14,
    textAlign: "center",
    lineHeight: 1.5,
  },
  copyright: {
    position: "absolute",
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "gray",
    bottom: 20,
    right: 20,
    // width: 100,
  },
  legendContainer: {
    position: "absolute",
    bottom: 40, // Adjust this value to move the legend up or down
    left: "50%",
    transform: "translateX(-50%)",
    flexDirection: "row",
    alignItems: "center",
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16, // spacing between legend items
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  legendText: {
    fontFamily: "mc-mark-narrow-medium",
    fontSize: 13,
  },
});

const SummaryPdf = ({ user, searchParams, summary }) => {
  console.log("searchParams: ", searchParams);

  const urlForChart = (data) => {
    chartConfig.data.labels = data.map((d) => d.name);
    chartConfig.data.datasets[0].data = data.map((d) => d.value);

    if (data.length === 2) {
      chartConfig.data.datasets[0].backgroundColor = [COLORS[0], COLORS[4]];
    } else {
      chartConfig.data.datasets[0].backgroundColor = data.map(
        (value, index) => COLORS[index % COLORS.length]
      );
    }

    const jsonString = JSON.stringify(chartConfig);
    const urlEncoded = encodeURIComponent(jsonString);

    const url =
      supabaseUrl + "/functions/v1/chart-images?chartConfig=" + urlEncoded;
    // console.log(url);
    return url;
  };

  const contentTypeDataForRegion = (region) => {
    return Object.entries(summary.contentTypesByRegion[region])
      .filter(([name]) => contentTypesForSummaryPage.includes(name))
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => a.value - b.value);
  };

  const passionCategoryDataForRegion = (region) => {
    return Object.entries(summary.passionCategoriesByRegion[region])
      .filter(([name]) => categoriesForSummaryPage.includes(name))
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => a.value - b.value);
  };

  const dataForChartType = (chartType) => {
    switch (chartType) {
      case "passionCategory":
        return (
          summary?.passion_category_counts &&
          Object.entries(summary.passion_category_counts)
            .filter(([name]) => categoriesForSummaryPage.includes(name))
            .sort((a, b) => a[1] - b[1])
            .map(([name, value]) => {
              return {
                name,
                value,
              };
            })
        );
      case "contentType":
        return (
          summary?.product_type_counts &&
          Object.entries(summary.product_type_counts)
            .filter(([name]) => contentTypesForSummaryPage.includes(name))
            .sort((a, b) => a[1] - b[1])
            .map(([name, value]) => ({
              name,
              value,
            }))
        );
      case "redemptionType":
        return (
          summary?.redemption_type_counts &&
          Object.entries(summary.redemption_type_counts)
            .filter(([name]) => redemptionTypesForSummaryPage.includes(name))
            .sort((a, b) => a[1] - b[1])
            .map(([name, value]) => ({
              name,
              value,
            }))
        );
      case "priceType":
        return [
          { name: "Free", value: summary.free_count },
          { name: "Paid", value: summary.paid_count },
        ];
      case "globalLocal":
        return [
          { name: "Global", value: summary.global_count },
          { name: "Local only", value: summary.local_only_count },
        ];
      case "iconicStatus":
        return [
          { name: "Iconic", value: summary.iconic_count },
          { name: "Non-iconic", value: summary.non_iconic_count },
        ];
      case "apiPTR":
        return [
          { name: "Allowed", value: summary.ptr_api_allowed_count },
          { name: "Not granted", value: summary.ptr_api_disallowed_count },
        ];
      case "cobrandPTR":
        return [
          { name: "Allowed", value: summary.ptr_cobrand_allowed_count },
          { name: "Not granted", value: summary.ptr_cobrand_disallowed_count },
        ];
      case "availabilityType":
        return [
          { name: "Live", value: summary.live_count },
          { name: "Coming Soon", value: summary.coming_soon_count },
        ];
      case "countsByRegion":
        return Object.entries(summary.region_counts || {})
          .sort(([, countA], [, countB]) => countA - countB)
          .map(([name, value]) => ({ name, value }));
      default:
        return null;
    }
  };

  const stats = generateStats(summary);

  const calculateLineOneString = () => {
    const parts = [
      searchParams.priceGroup && searchParams.priceGroup.length
        ? searchParams.priceGroup.join(" and ")
        : "",

      searchParams.globalAvailability && searchParams.globalAvailability.length
        ? searchParams.globalAvailability
            .join(" and ")
            ?.replace(/Available Globally/g, "Global")
        : "",

      searchParams.availabilityDate
        ? `Available on ${searchParams.availabilityDate}`
        : undefined,

      searchParams.ptr2 && searchParams.ptr2.length
        ? searchParams.ptr2
            .join(" and ")
            .replace(/PTR-API/g, "API PTR")
            .replace(/PTR-Cobrand/g, "Cobrand PTR")
            .replace(/,/g, " and ")
        : "",

      searchParams.contentStatus && searchParams.contentStatus.length
        ? searchParams.contentStatus.join(" and ")
        : "",
    ]
      .flat()
      .filter(Boolean);

    return parts.join(" | ");
  };

  const calculateLineTwoString = () => {
    return searchParams.countries && searchParams.countries.length > 0
      ? `Regions / Markets: ${searchParams.countries.join(", ")}`
      : undefined;
  };

  const calculateLineThreeString = () => {
    return searchParams.category && searchParams.category.length > 0
      ? `Passion Categories: ${searchParams.category.join(", ")}`
      : undefined;
  };

  const calculateLineFourString = () => {
    const parts = [
      searchParams.contentType?.join(", "),
      searchParams.redemptionType?.join(", "),
    ]
      .flat()
      .filter(Boolean);

    const resultString = parts.join(" | ");

    return resultString.length > 0
      ? `Content / Redemption Types: ${resultString}`
      : "";
  };

  const calculateLineFiveString = () => {
    return searchParams.subcategories && searchParams.subcategories.length > 0
      ? `Subcategories: ${searchParams.subcategories.join(", ")}`
      : undefined;
  };

  const calculateLineSixString = () => {
    return searchParams.languages && searchParams.languages.length > 0
      ? `Languages: ${searchParams.languages.join(", ")}`
      : undefined;
  };

  return (
    <Document>
      <Page
        key="coverPage"
        size="A4"
        orientation="landscape"
        style={coverStyles.page}
      >
        <Image
          source="./ma_priceless_standard_pos.png"
          style={coverStyles.image}
        />
        <Text style={coverStyles.title}>Content Catalog</Text>
        <Text style={coverStyles.date}>
          Search Summary exported on: {dayjs().format("YYYY-MM-DD")}
        </Text>

        <Text style={coverStyles.filtersTitleLabel}>Search Criteria:</Text>

        {calculateLineOneString().length > 0 && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineOneString()}
            </Text>
          </View>
        )}

        {calculateLineTwoString() && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineTwoString()}
            </Text>
          </View>
        )}

        {calculateLineThreeString() && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineThreeString()}
            </Text>
          </View>
        )}

        {calculateLineFourString().length > 0 && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineFourString()}
            </Text>
          </View>
        )}

        {calculateLineFiveString() && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineFiveString()}
            </Text>
          </View>
        )}

        {calculateLineSixString() && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineSixString()}
            </Text>
          </View>
        )}

        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Text style={coverStyles.copyright} fixed>
          Copyright {new Date().getFullYear()} Mastercard
        </Text>
      </Page>

      <Page size="A4" orientation="landscape" style={summaryStyles.page}>
        <Text style={summaryStyles.title}>Catalog Search Summary</Text>
        <View style={summaryStyles.statsContainer}>
          {stats.map((stat, index) => (
            <View
              key={index}
              style={[
                summaryStyles.statContainer,
                index === stats.length - 1
                  ? summaryStyles.lastStatContainer
                  : {},
              ]}
            >
              <Text style={summaryStyles.statName}>{stat.name}</Text>
              <Text style={summaryStyles.statValue}>{stat.value}</Text>
            </View>
          ))}
        </View>
        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Image src="/priceless_watermark.png" style={styles.logo} fixed />
      </Page>

      <Page
        key="chartsPage1"
        size="A4"
        orientation="landscape"
        style={chartPageStyles.page}
      >
        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>Passion Category</Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("passionCategory")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>Content Type</Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("contentType")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Image src="/priceless_watermark.png" style={styles.logo} fixed />
      </Page>

      <Page
        key="chartsPage2"
        size="A4"
        orientation="landscape"
        style={chartPageStyles.page}
      >
        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>Redemption Type</Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("redemptionType")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>Price</Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("priceType")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Image src="/priceless_watermark.png" style={styles.logo} fixed />
      </Page>

      <Page
        key="chartsPage3"
        size="A4"
        orientation="landscape"
        style={chartPageStyles.page}
      >
        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>Global vs. Local</Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("globalLocal")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>Iconic Status</Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("iconicStatus")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Image src="/priceless_watermark.png" style={styles.logo} fixed />
      </Page>

      <Page
        key="chartsPage4"
        size="A4"
        orientation="landscape"
        style={chartPageStyles.page}
      >
        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>
            API Pass-Through Rights
          </Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("apiPTR")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>
            Co-brand Pass-Through Rights
          </Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("cobrandPTR")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Image src="/priceless_watermark.png" style={styles.logo} fixed />
      </Page>

      <Page
        key="chartsPage5"
        size="A4"
        orientation="landscape"
        style={chartPageStyles.page}
      >
        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>Availability Type</Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("availabilityType")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <View style={chartPageStyles.imageContainer}>
          <Text style={chartPageStyles.imageTitle}>Content by Region</Text>
          <Image
            src={{
              uri: urlForChart(dataForChartType("countsByRegion")),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={chartPageStyles.image}
          />
        </View>

        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Image src="/priceless_watermark.png" style={styles.logo} fixed />
      </Page>

      <Page size="A4" orientation="landscape" style={summaryStyles.page}>
        <Text style={summaryStyles.fullPageTitle}>Content Types by Region</Text>

        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Image src="/priceless_watermark.png" style={styles.logo} fixed />
      </Page>

      {Object.keys(summary.contentTypesByRegion)
        .reduce((accumulator, currentValue, currentIndex, array) => {
          if (currentIndex % 2 === 0) {
            accumulator.push(array.slice(currentIndex, currentIndex + 2));
          }
          return accumulator;
        }, [])
        .map((p) => {
          const region1 = p[0];
          const region2 = p[1];

          return (
            <Page
              key={"chartsPage"}
              size="A4"
              orientation="landscape"
              style={chartPageStyles.page}
            >
              <View style={chartPageStyles.imageContainer}>
                <Text style={chartPageStyles.imageTitle}>{region1}</Text>
                <Image
                  src={{
                    uri: urlForChart(contentTypeDataForRegion(region1)),
                    method: "GET",
                    headers: { Authorization: `Bearer ${user.sessionToken}` },
                    body: "",
                  }}
                  style={chartPageStyles.image}
                />
              </View>

              {region2 !== undefined && (
                <View style={chartPageStyles.imageContainer}>
                  <Text style={chartPageStyles.imageTitle}>{region2}</Text>
                  <Image
                    src={{
                      uri: urlForChart(contentTypeDataForRegion(region2)),
                      method: "GET",
                      headers: { Authorization: `Bearer ${user.sessionToken}` },
                      body: "",
                    }}
                    style={chartPageStyles.image}
                  />
                </View>
              )}

              <Text style={styles.internalLabel}>
                For content planning use only
              </Text>
              <Image src="/priceless_watermark.png" style={styles.logo} fixed />
            </Page>
          );
        })}

      <Page size="A4" orientation="landscape" style={summaryStyles.page}>
        <Text style={summaryStyles.fullPageTitle}>
          Passion Categories by Region
        </Text>

        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Image src="/priceless_watermark.png" style={styles.logo} fixed />
      </Page>

      {Object.keys(summary.passionCategoriesByRegion)
        .reduce((accumulator, currentValue, currentIndex, array) => {
          if (currentIndex % 2 === 0) {
            accumulator.push(array.slice(currentIndex, currentIndex + 2));
          }
          return accumulator;
        }, [])
        .map((p) => {
          const region1 = p[0];
          const region2 = p[1];

          return (
            <Page
              key={"chartsPage"}
              size="A4"
              orientation="landscape"
              style={chartPageStyles.page}
            >
              <View style={chartPageStyles.imageContainer}>
                <Text style={chartPageStyles.imageTitle}>{region1}</Text>
                <Image
                  src={{
                    uri: urlForChart(passionCategoryDataForRegion(region1)),
                    method: "GET",
                    headers: { Authorization: `Bearer ${user.sessionToken}` },
                    body: "",
                  }}
                  style={chartPageStyles.image}
                />
              </View>

              {region2 !== undefined && (
                <View style={chartPageStyles.imageContainer}>
                  <Text style={chartPageStyles.imageTitle}>{region2}</Text>
                  <Image
                    src={{
                      uri: urlForChart(passionCategoryDataForRegion(region2)),
                      method: "GET",
                      headers: { Authorization: `Bearer ${user.sessionToken}` },
                      body: "",
                    }}
                    style={chartPageStyles.image}
                  />
                </View>
              )}

              <Text style={styles.internalLabel}>
                For content planning use only
              </Text>
              <Image src="/priceless_watermark.png" style={styles.logo} fixed />
            </Page>
          );
        })}
    </Document>
  );
};

export default SummaryPdf;

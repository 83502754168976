export const sports = [
    {
        "label": "Baseball",
        "value": "Baseball"
      },
      {
        "label": "Cricket",
        "value": "Cricket"
      },
      {
        "label": "Football",
        "value": "Football"
      },
      {
        "label": "Golf",
        "value": "Golf"
      },
      {
        "label": "Rugby",
        "value": "Rugby"
      },
      {
        "label": "Running",
        "value": "Running"
      },
      {
        "label": "Tennis",
        "value": "Tennis"
      },
      {
        "label": "Women's Sports",
        "value": "Women's Sports"
      },
      {
        "label": "Other",
        "value": "Other"
      },
]
import { Progress } from "antd";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { ClockIcon, StarIcon } from "@heroicons/react/24/solid";
import { FaMartiniGlass } from "react-icons/fa6";
import { formatCurrency } from "../../utils";
import { FaSeedling } from "react-icons/fa6";
import { isAdmin, useUser } from "../../UserProvider";

const getVisibilityString = (
  visibleOutsideOriginCountry,
  restrictedMarkets,
  reasonsForGeographicRestrictions
) => {
  let str = "";
  if (visibleOutsideOriginCountry === "yes") {
    str += "Available globally";
  } else if (visibleOutsideOriginCountry === "WithRestrictions") {
    str +=
      "Available outside origin, but may NOT be merchandized in " +
      restrictedMarkets.join(", ");
  } else {
    str +=
      "Available only in origin country - " + reasonsForGeographicRestrictions;
  }
  return str;
};

const getCobrandString = (cobrandSitePermissions, cobrandUsageRestrictions) => {
  let str = "";
  if (cobrandSitePermissions === "yes") {
    str += "Permitted";
  } else if (cobrandSitePermissions === "some") {
    str += "Restricted -  " + cobrandUsageRestrictions;
  } else {
    str += "No partner cobrand usage allowed";
  }
  return str;
};

const getApiString = (apiUsagePermissions, apiUsageRestrictions) => {
  let str = "";
  if (apiUsagePermissions === "yes") {
    str += "Permitted";
  } else if (apiUsagePermissions === "some") {
    str += "Restricted -  " + apiUsageRestrictions;
  } else {
    str += "No partner API usage allowed";
  }
  return str;
};

const getCategoryString = (passionCategory, subcategories, sport) => {
  let str = passionCategory;

  if (subcategories && subcategories.length > 0) {
    str += " - " + subcategories.join(", ");
  }

  if (sport) {
    str += " - " + sport;
  }
  return str;
};

const getInventoryString = (count, reservedDescription) => {
  let str = count;

  if (reservedDescription) {
    str += " - Inventory is reserved: " + reservedDescription;
  }
  return str;
};

export default function ComingSoonInfoView({ data, deleteHandler }) {
  const navigate = useNavigate();

  const { user } = useUser();

  const handleEdit = (event, data) => {
    event.stopPropagation();
    navigate("/update", { state: data });
  };

  return (
    <div>
      <div className="flex items-center space-x-2">
        <span className="inline-flex mcfont uppercase items-center rounded-full bg-yellow-50 px-2 py-1 my-1 text-xs font-semibold text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
          <ClockIcon className="align-top w-4 h-4 inline-block mr-1 " />
          Coming Soon
        </span>

        {data.isIconic && (
          <span className="inline-flex mcfont uppercase items-center rounded-full bg-yellow-50 px-2 py-1 my-1 text-xs font-semibold text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
            <StarIcon className="align-top w-4 h-4 inline-block mr-1 " />
            Iconic Experience
          </span>
        )}

        {data.ecoFriendly && (
          <span className="inline-flex mcfont uppercase items-center rounded-full bg-green-50 px-2 py-1 my-1 text-xs font-semibold text-green-700 ring-1 ring-inset ring-green-600/20">
            <FaSeedling className="align-top w-4 h-4 inline-block mr-1 " />
            Eco-friendly
          </span>
        )}

        {data.isAlcoholAffiliated && (
          <span className="inline-flex mcfont uppercase items-center rounded-full bg-yellow-50 px-2 py-1 my-1 text-xs font-semibold text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
            <FaMartiniGlass className="mr-1" />
            Alcohol Affiliated
          </span>
        )}
      </div>

      <div className="px-4 sm:px-0">
        <p className="mt-1 max-w-2xl mcfont text-md leading-6 ">
          {data.description}
        </p>
      </div>

      {(isAdmin(user) || data.user_id === user.id) && (
        <div className="flex items-center justify-right mt-5 space-x-4">
          <button
            type="button"
            onClick={(e) => handleEdit(e, data)}
            className="inline-flex items-center gap-x-1.5 rounded-md mcfont text-md font-semibold bg-orange-50 px-3 py-2  text-orange-600 shadow-sm hover:bg-orange-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Edit this content
          </button>
          <button
            type="button"
            onClick={(e) => deleteHandler(e, data)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-50 px-3 py-2 mcfont text-md font-semibold text-red-600 shadow-sm hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Delete
          </button>
        </div>
      )}

      <div className="mt-6 border-t border-gray-100 mcfont">
        <dl className="divide-y divide-gray-100">
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Product ID
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {" "}
              {data.id}
            </dd>
          </div>

          <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Go-Live Probability
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Progress
                className="mcfont"
                strokeColor="#CF4500"
                percent={data.goLiveProbability}
              />
            </dd>
          </div>
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Estimated Publish Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {" "}
              {data.publishDate}
            </dd>
          </div>
          <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Estimated Sales End Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {" "}
              {data.salesCloseDate}
            </dd>
          </div>
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Merchant
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.merchantName}
            </dd>
          </div>
          <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Price
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatCurrency(data.price, data.currencyCode)} for{" "}
              {data.peopleCount} people
            </dd>
          </div>
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Location
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.city}, {data.countryContentTakesPlace}
            </dd>
          </div>
          <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Initial Inventory Count
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {getInventoryString(
                data.initialInventoryCount,
                data.reservedInventoryDescription
              )}
            </dd>
          </div>
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Max Quantity per Order
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.maxQuantityPerOrder}
            </dd>
          </div>
          <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Redemption Type
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.redemptionType}
            </dd>
          </div>
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Content Type
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.contentType}
            </dd>
          </div>
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Special Product Types
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.special_product_types &&
              data.special_product_types.length > 0
                ? data.special_product_types.join(", ")
                : "-"}
            </dd>
          </div>
          <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Availability
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.isDateDriven ? "Date Driven" : "Always On"}
            </dd>
          </div>
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Categories
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {getCategoryString(
                data.passionCategory,
                data.subcategories,
                data.sport
              )}
            </dd>
          </div>
          <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Geographic Visibility
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {getVisibilityString(
                data.visibleOutsideOriginCountry,
                data.restrictedMarkets,
                data.reasonsForGeographicRestrictions
              )}
            </dd>
          </div>
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Co-brand Pass-Through Rights
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {getCobrandString(
                data.cobrandUsagePermissions,
                data.cobrandUsageRestrictions
              )}
            </dd>
          </div>
          <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              API Pass-Through Rights
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {getApiString(
                data.apiUsagePermissions,
                data.apiUsageRestrictions
              )}
            </dd>
          </div>
          <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Restricted Segments
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.restrictedSegments && data.restrictedSegments.length > 0
                ? data.restrictedSegments.join(", ")
                : "NONE"}
            </dd>
          </div>
          <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Comments
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.comments}
            </dd>
          </div>

          {data.linkedSponsorship && (
            <div className="even:bg-white odd:bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
              <dt className="text-sm font-semibold leading-6 text-gray-900">
                Linked sponsorship
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {data.linkedSponsorship}
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
}

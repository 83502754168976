export const segments = [
    {
        "label": "Airlines",
        "value": "Airlines"
    },
    {
        "label": "Amusement & Entertainment",
        "value": "Amusement & Entertainment"
    },
    {
        "label": "Automobiles & Vehicles",
        "value": "Automobiles & Vehicles"
    },
    {
        "label": "Business Services",
        "value": "Business Services"
    },
    {
        "label": "Car Rental",
        "value": "Car Rental"
    },
    {
        "label": "Clothing Stores",
        "value": "Clothing Stores"
    },
    {
        "label": "Contracted Services",
        "value": "Contracted Services"
    },
    {
        "label": "Financial Services ",
        "value": "Financial Services "
    },
    {
        "label": "Government Services",
        "value": "Government Services"
    },
    {
        "label": "Hotels/Motels",
        "value": "Hotels/Motels"
    },
    {
        "label": "Miscellaneous Stores",
        "value": "Miscellaneous Stores"
    },
    {
        "label": "Personal Service Providers",
        "value": "Personal Service Providers"
    },
    {
        "label": "Professional Services & Membership",
        "value": "Professional Services & Membership"
    },
    {
        "label": "Quasi-Cash",
        "value": "Quasi-Cash"
    },
    {
        "label": "Repair Services",
        "value": "Repair Services"
    },
    {
        "label": "Retail Stores",
        "value": "Retail Stores"
    },
    {
        "label": "Service Providers",
        "value": "Service Providers"
    },
    {
        "label": "Transportation",
        "value": "Transportation"
    },
    {
        "label": "Utilities",
        "value": "Utilities"
    },
    {
        "label": "Wholesale Distributors & Manufacture",
        "value": "Wholesale Distributors & Manufacture"
    }
]
import React, { useEffect, useState } from "react";

import {
  Tooltip,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
} from "recharts";
import { Row, Col, Collapse } from "antd";
import { categoriesForSummaryPage } from "../../data/categories";
import { redemptionTypesForSummaryPage } from "../../data/redemptionTypes";
import { contentTypesForSummaryPage } from "../../data/contentTypes";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { pdf } from "@react-pdf/renderer";
import SummaryPdf from "../../components/SummaryPdf";
import { useUser, isAdmin, hasExportPrivileges } from "../../UserProvider";
import { supabase } from "../../supabaseClient";
import { DocumentChartBarIcon } from "@heroicons/react/24/outline";
import { CaretRightOutlined } from "@ant-design/icons";

export const COLORS = ["#d22a2f", "#de3a2c", "#ea4928", "#f55823", "#ff671b"];

export const generateStats = (summary) => {
  const totalLanguages = Object.keys(summary.language_counts).length;
  const totalCountries = Object.keys(summary.country_counts).length;

  const stats = [
    {
      name: "Total Content Count",
      value: summary.total.toLocaleString(),
    },
    {
      name: "Global Content Count",
      value: summary.global_count.toLocaleString(),
    },
    {
      name: "Countries",
      value: totalCountries.toLocaleString(),
    },
    {
      name: "Languages",
      value: totalLanguages.toLocaleString(),
    },
  ];
  return stats;
};

const ContentSummary = ({ summary, currentSearchQuery }) => {
  let paramObject = currentSearchQuery;

  const [pdfLoading, setPdfLoading] = useState(false);
  const { user, languages, countriesByRegion } = useUser();

  const generateAndStorePDF = async () => {
    try {
      setPdfLoading(true);

      const doc = (
        <SummaryPdf user={user} searchParams={paramObject} summary={summary} />
      );

      const blob = await pdf(doc).toBlob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = `priceless_summary_${dayjs().format(
        "YYYY-MM-DDTHH:mm"
      )}.pdf`;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link);
    } catch (e) {
      console.error("Error generating PDF:", e);

      let log = JSON.stringify({
        error: e,
      });

      supabase.functions.invoke("pipeline-log", {
        body: { event: "PDF ERROR", body: log },
        headers: { Authorization: `Bearer ${user.sessionToken}` },
      });

      alert("Error generating product PDF. Please try again.");
    } finally {
      setPdfLoading(false);
    }
  };

  const panelStyle = {
    marginBottom: 24,
    border: "none",
  };

  const getItems = () => [
    {
      key: "1",
      label: "Content Types by Region",
      children: (
        <Row gutter={[16, 16]}>
          {Object.keys(summary.contentTypesByRegion).map((regionKey) => {
            const contentTypes = summary.contentTypesByRegion[regionKey];
            return (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="-mb-16"
                key={regionKey}
              >
                <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-12">
                  {regionKey}
                </h2>
                <GenericContentTypesChart
                  contentTypeCounts={contentTypes}
                  totalContentInRegion={summary.region_counts[regionKey]}
                />
              </Col>
            );
          })}
        </Row>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: "Passion Categories by Region",
      children: (
        <Row gutter={[16, 16]}>
          {Object.keys(summary.passionCategoriesByRegion).map((regionKey) => {
            const categoryCounts = summary.passionCategoriesByRegion[regionKey];
            return (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="-mb-16"
                key={regionKey}
              >
                <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-12">
                  {regionKey}
                </h2>
                <GenericPassionCategoriesChart
                  categoryCounts={categoryCounts}
                  totalContentInRegion={summary.region_counts[regionKey]}
                />
              </Col>
            );
          })}
        </Row>
      ),
      style: panelStyle,
    },
  ];

  const listOfFilters = Object.values(paramObject)
    .filter(
      (value) =>
        value != null && // Remove `null` and `undefined`
        !(typeof value === "string" && value.trim() === "") && // Remove empty strings or strings with only whitespaces
        !(Array.isArray(value) && value.length === 0) // Remove empty arrays
    )
    .map((value) => {
      if (typeof value === "string") {
        return value.replace(/,/g, ", ");
      } else if (Array.isArray(value)) {
        return value.join(", ");
      }
      return value;
    });

  // console.log(paramObject);

  return (
    <div className="content-summary">
      {summary && (
        <>
          <span className="mcfont text-md font-semibold text-[#CF4500] ">
            Content catalog last updated on: {dayjs().format("YYYY-MM-DD")}
          </span>

          {(isAdmin(user) || hasExportPrivileges(user)) && (
            <>
              <div className="my-4 mb-5">
                <button
                  type="button"
                  title={"Export search summary as a PDF"}
                  onClick={generateAndStorePDF}
                  disabled={pdfLoading}
                  className={`inline-flex items-center text-sm font-semibold mcfont rounded-md bg-orange-600 px-3 py-2 text-white shadow-md hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 ${
                    pdfLoading
                      ? "bg-orange-50 text-orange-600"
                      : "bg-orange-50 text-orange-600"
                  }`}
                >
                  <DocumentChartBarIcon
                    className="-ml-0.5 mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                  {pdfLoading ? "Generating PDF..." : "Export as PDF"}
                </button>
              </div>
            </>
          )}

          <div className="my-2">
            {listOfFilters.length > 0 && (
              <span className="mcfont text-md font-semibold mr-2 ">
                Filters:
              </span>
            )}
            {listOfFilters.map((paramValue) => (
              <span
                key={paramValue}
                className="inline-flex mcfont text-md font-semibold mr-1 mb-1 items-center rounded-full bg-orange-50 px-2 py-1 text-orange-600 ring-1 ring-inset ring-orange-500/10"
              >
                {paramValue}
              </span>
            ))}
          </div>

          <Row gutter={[16, 16]}>
            <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
              {generateStats(summary).map((stat) => (
                <div
                  key={stat.name}
                  className="mcfont flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
                >
                  <dt className="text-sm font-semibold leading-6 text-gray-700">
                    {stat.name}
                  </dt>
                  <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-12">
                Passion Category
              </h2>
              <PassionCategoryChart summary={summary} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-12">
                Content Type
              </h2>
              <ContentTypesChart summary={summary} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-16">
                Redemption Type
              </h2>
              <RedemptionTypesChart summary={summary} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-16">
                Price
              </h2>
              <PriceChart summary={summary} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-16">
                Global vs. Local
              </h2>
              <GlobalLocalChart summary={summary} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-16">
                API Pass-Through Rights
              </h2>
              <ApiPtrChart summary={summary} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-16">
                Co-brand Pass-Through Rights
              </h2>
              <CobrandPtrChart summary={summary} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-16">
                Iconic Status
              </h2>
              <IconicChart summary={summary} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-16">
                Availability Type
              </h2>
              <LiveComingSoonChart summary={summary} />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="-mb-16">
              <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-6 -mb-16">
                Content by Region
              </h2>
              <CountsByRegionChart summary={summary} />
            </Col>

            <Collapse
              ghost
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  className="w-8 h-8"
                  style={{
                    color: "#d22a2f",
                    fontSize: "24px",
                    paddingTop: "10px",
                  }}
                  rotate={isActive ? 90 : 0}
                />
              )}
              className="w-screen mcfont text-xl text-gray-700 font-semibold"
              // style={{ background: token.colorBgContainer }}
              items={getItems()}
            />

            {!isEmpty(summary.language_counts) && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="-mb-16">
                <h2 className="mcfont text-xl text-gray-700 font-semibold text-center mt-4 mb-4">
                  Top Languages
                </h2>
                <TopLanguagesChart summary={summary} />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default ContentSummary;

const GenericCustomTooltip = ({ active, payload, label, denominator }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white p-4 shadow-lg rounded-lg">
        <p className="mcfont text-md font-semibold label">{`${
          label ? label : payload[0].name
        }: ${payload[0].value.toLocaleString()} items`}</p>
        <p className="mcfont">
          {((payload[0].value / denominator) * 100).toFixed(2)}%
        </p>
      </div>
    );
  }

  return null;
};

const CustomTooltip = ({ active, payload, label, summary, denominator }) => {
  if (!denominator) {
    denominator = summary.total;
  }

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white p-4 shadow-lg rounded-lg">
        <p className="mcfont text-md font-semibold label">{`${
          label ? label : payload[0].name
        }: ${payload[0].value.toLocaleString()} items`}</p>
        <p className="mcfont">
          {((payload[0].value / denominator) * 100).toFixed(2)}%
        </p>
      </div>
    );
  }

  return null;
};

const LiveComingSoonChart = ({ summary }) => {
  const data = [
    { name: "Live", value: summary.live_count },
    { name: "Coming Soon", value: summary.coming_soon_count },
  ];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
          paddingAngle={3}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={index === 0 ? COLORS[0] : COLORS[4]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip summary={summary} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const GlobalLocalChart = ({ summary }) => {
  const data = [
    { name: "Global", value: summary.global_count },
    { name: "Local only", value: summary.local_only_count },
  ];

  const total = summary.global_count + summary.local_only_count;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
          paddingAngle={3}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={index === 0 ? COLORS[0] : COLORS[4]}
            />
          ))}
        </Pie>
        <Tooltip
          content={<CustomTooltip summary={summary} denominator={total} />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const PriceChart = ({ summary }) => {
  const data = [
    { name: "Free", value: summary.free_count },
    { name: "Paid", value: summary.paid_count },
  ];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
          paddingAngle={3}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={index === 0 ? COLORS[0] : COLORS[4]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip summary={summary} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const ApiPtrChart = ({ summary }) => {
  const data = [
    { name: "Allowed", value: summary.ptr_api_allowed_count },
    { name: "Not granted", value: summary.ptr_api_disallowed_count },
  ];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
          paddingAngle={3}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={index === 0 ? COLORS[0] : COLORS[4]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip summary={summary} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const CobrandPtrChart = ({ summary }) => {
  const data = [
    { name: "Allowed", value: summary.ptr_cobrand_allowed_count },
    { name: "Not granted", value: summary.ptr_cobrand_disallowed_count },
  ];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
          paddingAngle={3}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={index === 0 ? COLORS[0] : COLORS[4]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip summary={summary} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const IconicChart = ({ summary }) => {
  const data = [
    { name: "Iconic", value: summary.iconic_count },
    { name: "Non-iconic", value: summary.non_iconic_count },
  ];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
          paddingAngle={3}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={index === 0 ? COLORS[0] : COLORS[4]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip summary={summary} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const GenericContentTypesChart = ({
  contentTypeCounts,
  totalContentInRegion,
}) => {
  const contentTypeData = Object.entries(contentTypeCounts)
    .filter(([name]) => contentTypesForSummaryPage.includes(name))
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => a.value - b.value);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          dataKey="value"
          data={contentTypeData}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={3}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
        >
          {contentTypeData.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip
          content={<GenericCustomTooltip denominator={totalContentInRegion} />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const GenericPassionCategoriesChart = ({
  categoryCounts,
  totalContentInRegion,
}) => {
  const passionCategoryData = Object.entries(categoryCounts)
    .filter(([name]) => categoriesForSummaryPage.includes(name))
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => a.value - b.value);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          dataKey="value"
          data={passionCategoryData}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={3}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
        >
          {passionCategoryData.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip
          content={<GenericCustomTooltip denominator={totalContentInRegion} />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const PassionCategoryChart = ({ summary }) => {
  const passionCategoryData =
    summary?.passion_category_counts &&
    Object.entries(summary.passion_category_counts)
      .filter(([name]) => categoriesForSummaryPage.includes(name))
      .sort((a, b) => a[1] - b[1])
      .map(([name, value]) => {
        return {
          name,
          value,
        };
      });

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          dataKey="value"
          data={passionCategoryData}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={3}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
        >
          {passionCategoryData.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip summary={summary} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const RedemptionTypesChart = ({ summary }) => {
  const redemptionTypeData =
    summary?.redemption_type_counts &&
    Object.entries(summary.redemption_type_counts)
      .filter(([name]) => redemptionTypesForSummaryPage.includes(name))
      .sort((a, b) => a[1] - b[1])
      .map(([name, value]) => ({
        name,
        value,
      }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          dataKey="value"
          data={redemptionTypeData}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={3}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
        >
          {redemptionTypeData.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip summary={summary} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const ContentTypesChart = ({ summary }) => {
  const contentTypeData =
    summary?.product_type_counts &&
    Object.entries(summary.product_type_counts)
      .filter(([name]) => contentTypesForSummaryPage.includes(name))
      .sort((a, b) => a[1] - b[1])
      .map(([name, value]) => ({
        name,
        value,
      }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          dataKey="value"
          data={contentTypeData}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={3}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
        >
          {contentTypeData.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip summary={summary} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const TopLanguagesChart = ({ summary }) => {
  // Extract the top 10 languages by count
  const topLanguages = Object.entries(summary.language_counts || {})
    .sort(([, countA], [, countB]) => countB - countA)
    .slice(0, 10)
    .map(([name, value]) => ({ name, value }));

  return (
    <ResponsiveContainer width="100%" height={400} className="mb-20">
      <BarChart
        margin={{ top: 0, right: 0, left: 50, bottom: 0 }}
        layout="vertical"
        data={topLanguages}
        fontFamily="MarkForMC"
        fontWeight={600}
      >
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" />
        <Tooltip content={<CustomTooltip summary={summary} />} />
        <Bar dataKey="value" fill={COLORS[0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

const CountsByRegionChart = ({ summary }) => {
  const topRegions = Object.entries(summary.region_counts || {})
    .sort(([, countA], [, countB]) => countA - countB)
    .map(([name, value]) => ({ name, value }));

  return (
    <ResponsiveContainer width="100%" height={400} className="mb-20">
      <PieChart>
        <Pie
          dataKey="value"
          data={topRegions}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={3}
          fontFamily="MarkForMC"
          fontWeight={600}
          label={({ name }) => name}
        >
          {topRegions.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip summary={summary} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

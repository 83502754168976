import { createClient } from "@supabase/supabase-js";

export const supabaseUrl = "https://app.pricelesscontentcatalog.com";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ5a3p0ZmpzaGJ4eWt5dWVpdnJtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODY1NDE1ODksImV4cCI6MjAwMjExNzU4OX0.O7nunnAmOORt5t7UCi03ZnGvAlNa2nyp8eeuVg-Lp6o";

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Used for making authorized API requests to supabase.
export async function fetchRoute(route, authToken) {
  return fetch(supabaseUrl + "/rest/v1/" + route, {
    method: "GET",
    headers: {
      apikey: supabaseAnonKey,
      Authorization: `Bearer ${authToken}`,
    },
  });
}

export default supabase;

export const subcategories = [
  {
    "label": "Children Allowed",
    "value": "Children Allowed"
  },
  {
    "label": "Cinema",
    "value": "Cinema"
  },
  {
    "label": "Cooking Classes",
    "value": "Cooking Classes"
  },
  {
    "label": "Family-friendly",
    "value": "Family Friendly"
  },
  {
    "label": "Gaming",
    "value": "Gaming"
  },
  {
    "label": "Hotels",
    "value": "Hotels"
  },
  {
    "label": "Museums",
    "value": "Museums"
  },
  {
    "label": "Music",
    "value": "Music"
  },
  {
    "label": "Nature and Outdoors",
    "value": "Nature and Outdoors"
  },
  {
    "label": "Pets Allowed",
    "value": "Pets Allowed"
  },
  {
    "label": "Restaurants",
    "value": "Restaurants"
  },
  {
    "label": "Theatres",
    "value": "Theaters"
  },
  {
    "label": "Wine & Cocktails",
    "value": "Wine & Cocktails"
  },
]
import React from 'react';

const UnauthorizedPage = () => {
  return (
    <div className="flex items-center justify-center h-screen">
    <div className="text-center">
      <h1 className="text-4xl font-bold mcfont text-orange-600">Unauthorized</h1>
      <p className="mt-2 text-lg mcfont text-gray-700">
        Sorry, we weren't able to authenticate you. Please refresh the page and try again.
      </p>
    </div>
  </div>
  );
};

export default UnauthorizedPage;
export const convertToCSV = (data) => {
  const header = Object.keys(data[0]).join(",");
  const rows = data.map((row) =>
    Object.values(row)
      .map((value) => {
        // If the value is an array, join it with commas and enclose in double quotes
        if (Array.isArray(value)) {
          return `"${value.join(", ")}"`;
        }
        // If the value is a string containing commas, enclose it in double quotes
        if (typeof value === "string") {
          return `"${value.replace(/"/g, '""')}"`;
        }
        // Otherwise, return the value as is
        return value;
      })
      .join(",")
  );
  return [header, ...rows].join("\n");
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function stripHtmlTags(str) {
  if (str === null) {
    return null;
  } else if (str === "") {
    return "";
  } else {
    return str.replace(/(<([^>]+)>)/gi, "");
  }
}

export function decodeHtml(htmlString) {
  var txt = document.createElement("textarea");
  txt.innerHTML = htmlString;
  return txt.value;
}

export function cleanString(str) {
    if (str === null) {
        return null;
    }
    return decodeHtml(stripHtmlTags(str));
}

export function formatCurrency(price, currencyCode, locale = "en-US") {
  // Check if price or currencyCode is null
  if (price == null || currencyCode == null) {
      return "-";
  }

  // Format the price using the currencyCode and locale
  return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
  }).format(price);
}

export const isEmpty = obj => Object.keys(obj).length === 0;

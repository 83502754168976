export const categories = [{
	"label": "Arts and Culture",
	"value": "Arts and Culture"
},
{
	"label": "Culinary",
	"value": "Culinary"
},
{
	"label": "Music and Entertainment",
	"value": "Entertainment"
},
{
	"label": "Fashion and Shopping",
	"value": "Shopping"
},
{
	"label": "Sports",
	"value": "Sports"
},
{
	"label": "Travel",
	"value": "Travel"
},
{
	"label": "Health and Wellness",
	"value": "Health and Wellness"
}]

export const categoriesForSummaryPage = [
	"Arts and Culture", "Culinary", "Entertainment", "Shopping", "Sports", "Travel", "Health and Wellness"
]
import { useUser } from "./UserProvider"; 
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { user } = useUser();

  if (!user.sessionToken) {
    return <Navigate to="/unauthorized" replace />; 
  }

  return children;
}

export default ProtectedRoute;
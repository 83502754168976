import { Divider, Progress } from "antd";
import { BoltIcon, ClockIcon, StarIcon } from "@heroicons/react/24/solid";
import { FaMartiniGlass } from "react-icons/fa6";
import { cleanString, formatCurrency } from "../../utils";
import dayjs from "dayjs";
import { useUser } from "../../UserProvider";
import { supabase } from "../../supabaseClient";
import { useEffect, useState } from "react";
import { FaSeedling } from "react-icons/fa6";
import { PriceInformation } from "../../components/ProductCard";

function imagePathForCategory(type) {
  switch (type) {
    case "Arts and Culture":
      return "./img/arts_culture.jpg";
    case "Culinary":
      return "./img/culinary.jpg";
    case "Entertainment":
      return "./img/entertainment.jpg";
    case "Shopping":
      return "./img/shopping.jpg";
    case "Sports":
      return "./img/sports.jpg";
    case "Travel":
      return "./img/travel.jpg";
    case "Health and Wellness":
      return "./img/health_wellness.jpg";
    default:
      return "";
  }
}

function imagePathForData(data) {
  if (data.productImageUrl) {
    return data.productImageUrl;
  } else {
    return imagePathForCategory(data.passionCategory);
  }
}

const getVisibilityString = (isGlobal, restrictedMarkets) => {
  let str = "";
  if (isGlobal) {
    str += "Available globally";

    if (restrictedMarkets && restrictedMarkets.length > 0) {
      str +=
        ". RESTRICTIONS: May NOT be merchandized in " +
        restrictedMarkets.join(", ");
    }
  } else {
    str += "Product can be merchandized ONLY in origin country.";
  }
  return str;
};

const getCobrandString = (ptr, cobrandUsageRestrictions) => {
  let str = "";
  if (ptr === true) {
    str += "Permitted";
  } else {
    str += "Usage by customer co-branded sites is NOT allowed.";
  }
  return str;
};

const getApiString = (ptr, apiUsageRestrictions) => {
  let str = "";
  if (ptr === true) {
    str += "Permitted";
  } else {
    str += "Usage by customer API integrations is NOT allowed.";
  }
  return str;
};

const getCategoryString = (passionCategory, subcategories, sport) => {
  let str = passionCategory;

  if (subcategories && subcategories.length > 0) {
    str += " - " + subcategories.join(", ");
  }

  if (sport) {
    str += " - " + sport;
  }
  return cleanString(str);
};

const getInventoryString = (count, reservedDescription) => {
  let str = count;

  if (reservedDescription) {
    str += " - Inventory is reserved: " + reservedDescription;
  }
  return str;
};

export default function ProductInfoView({ data }) {
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [childProducts, setChildProducts] = useState([]);

  useEffect(() => {
    async function fetchChildProducts() {
      setLoading(true);
      const { data: childData, error } = await supabase.functions.invoke(
        "products-get-children",
        {
          body: {
            masterProductId: data.id,
          },
          headers: { Authorization: `Bearer ${user.sessionToken}` },
        }
      );

      if (childData) {
        setChildProducts(childData.data);
        // console.log("childData is: ", childData);
      } else if (error) {
        console.log("error is: ", error);
      }
      setLoading(false);
    }

    fetchChildProducts();
  }, [data, user.sessionToken]);

  return (
    <div>
      {loading ? (
        <div className="flex flex-col items-center mt-24">
          <svg
            className="w-20 h-20 text-orange-600 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <div className="absolute inset-0 bg-transparent pointer-events-auto"></div>
        </div>
      ) : (
        <>
          <img
            src={imagePathForData(data)}
            alt={data.title}
            className="w-full object-cover rounded-lg mb-4 "
          />

          <div className="flex items-center space-x-2 py-1">
            {data.status === "coming_soon" && (
              <span className="inline-flex mcfont uppercase items-center rounded-full bg-yellow-50 px-2 py-1 my-1 text-xs font-semibold text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                <ClockIcon className="align-top w-4 h-4 inline-block mr-1 " />
                Coming Soon
              </span>
            )}

            {data.status === "live" && (
              <span className="inline-flex mcfont uppercase items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                <BoltIcon className="align-top w-4 h-4 inline-block mr-1 " />
                Live
              </span>
            )}

            {data.iconic && (
              <span className="inline-flex mcfont uppercase items-center rounded-full bg-yellow-50 px-2 py-1 my-1 text-xs font-semibold text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                <StarIcon className="align-top w-4 h-4 inline-block mr-1 " />
                Iconic Experience
              </span>
            )}

            {data.ecoFriendly && (
              <span className="inline-flex mcfont uppercase items-center rounded-full bg-green-50 px-2 py-1 my-1 text-xs font-semibold text-green-700 ring-1 ring-inset ring-green-600/20">
                <FaSeedling className="align-top w-4 h-4 inline-block mr-1 " />
                Eco-friendly
              </span>
            )}

            {data.isAlcoholAffiliated && (
              <span className="inline-flex mcfont uppercase items-center rounded-full bg-yellow-50 px-2 py-1 my-1 text-xs font-semibold text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                <FaMartiniGlass className="mr-1" />
                Alcohol Affiliated
              </span>
            )}
          </div>

          <p className="my-2 mcfont text-md ">
            {cleanString(data.description)}
          </p>

          {data.status === "live" && (
            <a
              href={data.productUrl}
              target="_blank"
              rel="noreferrer"
              className="mcfont text-[#CF4500] hover:text-red-800 pt-2 py-4 text-md font-semibold "
            >
              View on priceless.com <span aria-hidden="true">→</span>
            </a>
          )}

          <div className="mt-6 border-t border-gray-100 mcfont">
            <dl className="divide-y divide-gray-100">
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Product ID
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {" "}
                  {data.id}
                </dd>
              </div>

              {data.languageNameList && data.languageNameList.length > 0 && (
                <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    Languages
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {" "}
                    {data.languageNameList.join(", ")}
                  </dd>
                </div>
              )}

              {data.status === "coming_soon" && (
                <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    Go-Live Probability
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <Progress
                      className="mcfont"
                      strokeColor="#CF4500"
                      percent={data.goLiveProbability}
                    />
                  </dd>
                </div>
              )}

              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  {data.status === "coming_soon"
                    ? "Estimated Publish Date"
                    : "Go-Live Date"}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {" "}
                  {data.startDate === "1900-01-01"
                    ? "Not provided"
                    : dayjs(data.startDate).format("MMMM D, YYYY")}
                </dd>
              </div>

              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  {data.status === "coming_soon"
                    ? "Estimated Sales Close Date"
                    : "Close Date"}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {" "}
                  {data.endDate === "2300-12-12"
                    ? "Not provided"
                    : dayjs(data.endDate).format("MMMM D, YYYY")}
                </dd>
              </div>

              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Merchant
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {cleanString(data.merchant)}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Price
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <PriceInformation data={data} />
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Location
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {cleanString(
                    [data.city, data.country].filter(Boolean).join(", ")
                  )}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Initial Inventory Count
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {getInventoryString(
                    data.initialInventoryCount,
                    data.reservedInventoryDescription
                  )}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Max Quantity per Order
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data.maxQuantityPerOrder}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Redemption Type
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data.redemptionType}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Content Type
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data.contentType}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Initiative Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data.initiativeName}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Program Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data.programName}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Special Product Types
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data.special_product_types &&
                  data.special_product_types.length > 0
                    ? data.special_product_types.join(", ")
                    : "-"}
                </dd>
              </div>

              {data.eventTime && (
                <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    Event Date
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data.eventTime === "2300-12-12T00:00:00"
                      ? "Not provided"
                      : dayjs(data.eventTime).format("MMMM D, YYYY, h:mm A")}
                  </dd>
                </div>
              )}

              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Categories
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {getCategoryString(
                    data.passionCategory,
                    data.subcategories,
                    cleanString(data.sport)
                  )}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Geographic Visibility
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {getVisibilityString(data.isGlobal, data.restrictedMarkets)}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Co-brand Pass-Through Rights
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {getCobrandString(
                    data.passThroughCobrandRights,
                    data.cobrandUsageRestrictions
                  )}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  API Pass-Through Rights
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {getApiString(
                    data.passThroughApiRights,
                    data.apiUsageRestrictions
                  )}
                </dd>
              </div>
              <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Restricted Segments
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data.restrictedSegments && data.restrictedSegments.length > 0
                    ? data.restrictedSegments.join(", ")
                    : "NONE"}
                </dd>
              </div>

              {data.comments && (
                <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    Comments
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data.comments}
                  </dd>
                </div>
              )}

              {data.linkedSponsorship && (
                <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    Linked sponsorship
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data.linkedSponsorship}
                  </dd>
                </div>
              )}
            </dl>
          </div>

          {childProducts && childProducts.length > 0 && (
            <>
              <Divider className="mt-8" />
              <div className="mt-6 sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-lg mcfont font-semibold leading-6 text-gray-900">
                    Content Options
                  </h1>
                </div>
              </div>

              <dl className="mcfont mt-4 divide-y divide-gray-100">
                {childProducts.map((prod) => (
                  <div className="odd:bg-gray-50 even:bg-white px-4 py-6 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-3 sm:items-center">
                    <dt className="text-sm leading-6 text-gray-700 sm:col-span-1">
                      {prod.id}
                    </dt>
                    <dd className="mt-1 text-sm font-semibold leading-6 text-gray-900 sm:col-span-5 sm:mt-0">
                      {prod.productName}
                      <br />
                      <span className="font-normal">
                        {formatCurrency(prod.price, prod.currencyCode)}
                      </span>
                      {" - "}
                      <span className="font-normal">
                        {[prod.city, prod.country].filter(Boolean).join(", ")}
                        {prod.eventTime
                          ? ` - ${dayjs(prod.eventTime).format(
                              "MMMM D, YYYY, h:mm A"
                            )}`
                          : ""}
                      </span>
                    </dd>
                  </div>
                ))}
              </dl>
            </>
          )}
        </>
      )}
    </div>
  );
}

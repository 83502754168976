export const contentTypes = [{
	"label": "In-Person Experience",
	"value": "In-Person Experience"
},
{
	"label": "Digital Experience",
	"value": "Digital Experience"
},
{
	"label": "Editorial Content",
	"value": "Editorial Content"
},
{
	"label": "Offer",
	"value": "Offer"
},
{
	"label": "EPP Offer",
	"value": "EPP Offer"
},
{
	"label": "Physical Product (e.g. Priceless Fragrances)",
	"value": "Physical Product"
}];

export const contentTypesCondensed = [{
	"label": "In-Person Experience",
	"value": "In-Person Experience"
},
{
	"label": "Digital Experience",
	"value": "Digital Experience"
},
{
	"label": "Editorial Content",
	"value": "Editorial Content"
},
{
	"label": "Offer",
	"value": "Offer"
},
{
	"label": "EPP Offer",
	"value": "EPP Offer"
},
{
	"label": "Physical Product",
	"value": "Physical Product"
}];

export const contentTypesForSummaryPage = [
	"In-Person Experience",
	"Digital Experience",
	"Editorial Content",
	"Offer",
	"EPP Offer",
	"Physical Product"
];
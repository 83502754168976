export const redemptionTypes = [{
    "label": "Auction",
    "value": "Auction"
},
{
    "label": "Check-out",
    "value": "Check-out"
},
{
    "label": "Punch-out",
    "value": "Punch-out"
},
{
    "label": "Sweepstakes",
    "value": "Sweepstake"
},
{
    "label": "Donation",
    "value": "Donation"
}]


export const redemptionTypesForSummaryPage = [
	"Auction", "Check-out", "Punch-out", "Sweepstake", "Donation"
]
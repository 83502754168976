export const sponsors = [{
  "label": "AIG Womens British Open",
  "value": "AIG Womens British Open"
},
{
  "label": "Arnold Palmer Invitational",
  "value": "Arnold Palmer Invitational"
},
{
  "label": "Arsenal Women's Football Club",
  "value": "Arsenal Women's Football Club"
},
{
  "label": "Australian Open",
  "value": "Australian Open"
},
{
  "label": "Berlinale",
  "value": "Berlinale"
},
{
  "label": "Cannes Festival",
  "value": "Cannes Festival"
},
{
  "label": "Carnegie Hall",
  "value": "Carnegie Hall"
},
{
  "label": "Cirque du Soleil",
  "value": "Cirque du Soleil"
},
{
  "label": "CONMEBOL Libertadores",
  "value": "CONMEBOL Libertadores"
},
{
  "label": "Disnelyland Paris",
  "value": "Disnelyland Paris"
},
{
  "label": "Goodwood Festival of Speed",
  "value": "Goodwood Festival of Speed"
},
{
  "label": "GRAMMYs",
  "value": "GRAMMYs"
},
{
  "label": "JRE",
  "value": "JRE"
},
{
  "label": "Latin GRAMMYs",
  "value": "Latin GRAMMYs"
},
{
  "label": "League of Legends",
  "value": "League of Legends"
},
{
  "label": "L'Orchestre de Paris",
  "value": "L'Orchestre de Paris"
},
{
  "label": "Major League Baseball",
  "value": "Major League Baseball"
},
{
  "label": "Museum het Rembrandthuis",
  "value": "Museum het Rembrandthuis"
},
{
  "label": "Museo Thyssen",
  "value": "Museo Thyssen"
},
{
  "label": "NYC Ballet",
  "value": "NYC Ballet"
},
{
  "label": "NYC Marathon",
  "value": "NYC Marathon"
},
{
  "label": "Olivier Awards",
  "value": "Olivier Awards"
},
{
  "label": "One World Observatory",
  "value": "One World Observatory"
},
{
  "label": "Open Championship",
  "value": "Open Championship"
},
{
  "label": "Opera National de Paris",
  "value": "Opera National de Paris"
},
{
  "label": "PGA Tour",
  "value": "PGA Tour"
},
{
  "label": "Roland-Garros",
  "value": "Roland-Garros"
},
{
  "label": "Royal Theatre",
  "value": "Royal Theatre"
},
{
  "label": "Rugby World Cup",
  "value": "Rugby World Cup"
},
{
  "label": "Sydney Opera House",
  "value": "Sydney Opera House"
},
{
  "label": "Teatro Real",
  "value": "Teatro Real"
},
{
  "label": "The BRIT Awards",
  "value": "The BRIT Awards"
},
{
  "label": "The Open Championship",
  "value": "The Open Championship"
},
{
  "label": "UEFA Champions League",
  "value": "UEFA Champions League"
},
{
  "label": "Ultra Europe",
  "value": "Ultra Europe"
},
{
  "label": "Venice Film Festival",
  "value": "Venice Film Festival"
},
{
  "label": "Other",
  "value": "Other"
}]
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Link,
  Font,
} from "@react-pdf/renderer";
import { PriceInformationAsString, imagePathForCategory } from "./ProductCard";
import { cleanString } from "../utils";
import { supabaseUrl } from "../supabaseClient";
import dayjs from "dayjs";

Font.register({
  family: "mc-mark-narrow-medium",
  src: "/fonts/mark/MarkForMCNarrowW00-Medium.ttf",
});

Font.register({
  family: "mc-mark-narrow-book",
  src: "/fonts/mark/MarkForMCNarrowW00-Book.ttf",
});

Font.register({
  family: "Open Sans",
  src: "/fonts/OpenSans-SemiBold.ttf",
});

// Colors used by the charts.
export const COLORS = ["#d22a2f", "#de3a2c", "#ea4928", "#f55823", "#ff671b"];

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#f3f4f6",
    padding: 10,
    paddingLeft: 20,
    paddingTop: 20,
  },
  card: {
    flexDirection: "column",
    height: 260,
    // padding: 20,
    backgroundColor: "white",
    borderColor: "gray",
    borderWidth: 1,
    width: 185, // "23%", // Adjust based on required gaps
    marginBottom: 10,
    marginRight: "2%",
  },
  productImage: {
    width: "100%", // or set a specific width
    height: 100, // adjust height as necessary
    marginBottom: 6, // optional spacing
  },
  category: {
    fontSize: 10,
    fontFamily: "mc-mark-narrow-medium",
    color: "#ea580c",
    textTransform: "uppercase",
    paddingLeft: 5,
    paddingRight: 5,
  },
  title: {
    marginTop: 5,
    fontSize: 11,
    fontFamily: "Open Sans",
    color: "black",
    paddingLeft: 5,
    paddingRight: 5,
    maxHeight: 56,
  },
  locationContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    width: "100%",
    maxHeight: 24,
  },
  ptrContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  locationIcon: {
    marginLeft: 3,
    width: 12,
    height: 12,
    marginRight: 5,
  },
  location: {
    fontSize: 11,
    maxWidth: "85%", // Adjust this percentage based on results
    fontFamily: "mc-mark-narrow-medium",
    color: "#1F2937",
  },
  price: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    maxWidth: "90%",
  },
  priceIcon: {
    marginLeft: 3,
    width: 12,
    height: 12,
    marginRight: 5,
  },
  priceText: {
    fontSize: 11,
    fontFamily: "mc-mark-narrow-medium",
    color: "#1F2937",
  },
  tag: {
    marginTop: 5,
    fontSize: 10,
    color: "#1F2937",
    fontFamily: "mc-mark-narrow-book",
    backgroundColor: "#FFD700", // approximated color for bg-yellow-50
    padding: 2,
    marginRight: 5,
  },
  liveNationTag: {
    marginTop: 5,
    fontSize: 10,
    color: "white",
    fontFamily: "mc-mark-narrow-book",
    backgroundColor: "#E01836",
    padding: 2,
    marginRight: 5,
  },
  liveNationIcon: {
    marginLeft: 0,
    width: 24,
    height: 24,
    padding: 0,
    marginRight: 1,
  },
  tagContainer: {
    flexDirection: "row",
    position: "absolute",
    bottom: 7,
    marginLeft: 5,
  },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "gray",
  },
  logo: {
    position: "absolute",
    bottom: 0,
    right: 20,
    width: 100,
    height: 50,
  },
  internalLabel: {
    position: "absolute",
    bottom: 20,
    left: 20,
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "gray",
    width: 500,
  },
  passionCategoryContainer: {
    position: "relative",
  },
  iconContainer: {
    position: "absolute",
    right: 0, // Adjust this value if you need some spacing to the right
    flexDirection: "row",
  },
  icon: {
    marginRight: 4,
    width: 12,
    height: 12,
  },
  badge: {
    position: "absolute", // this will position the badge on top of the image
    backgroundColor: "rgba(254,252,232,0.9)",
    padding: 3,
    borderRadius: 3,
    bottom: 10,
    left: 5,
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "#854D0E",
    textTransform: "uppercase",
    textDecoration: "none",
  },
  endDateLabel: {
    position: "absolute", // this will position the badge on top of the image
    backgroundColor: "rgba(255,255,255,0.8)",
    padding: 3,
    borderRadius: 3,
    bottom: 10,
    right: 5,
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "#854D0E",
    textTransform: "uppercase",
    textDecoration: "none",
  },
  imageContainer: {
    position: "relative",
  },
});

const gapPageStyles = StyleSheet.create({
  page: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 30,
    backgroundColor: "#f3f4f6",
  },
  title: {
    fontSize: 32,
    fontFamily: "mc-mark-narrow-medium",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 20,
  },
});

const chartPageStyles = StyleSheet.create({
  page: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 30, // Add padding if needed
  },
  imageContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10, // Add some spacing between the images
    width: "50%", // each container takes up half the width of the page
    height: "80%", // we allocate 80% of the page's height to the image
  },
  imageTitle: {
    textAlign: "center",
    fontSize: 26,
    fontFamily: "mc-mark-narrow-medium",
    fontWeight: "bold",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    aspectRatio: 1, // ensure square shape
  },
});

const summaryStyles = StyleSheet.create({
  page: {
    backgroundColor: "#f3f4f6",
    padding: 30,
  },
  title: {
    fontSize: 32,
    fontFamily: "mc-mark-narrow-medium",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 40,
  },
  statsContainer: {
    marginTop: 100,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  statContainer: {
    flex: 1,
    justifyContent: "center",
    borderRightWidth: 1,
    borderRightColor: "#E0E0E0",
    paddingHorizontal: 8,
  },
  lastStatContainer: {
    borderRightWidth: 0, // Remove border for the last stat
  },
  statName: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "mc-mark-narrow-medium",
    color: "#C2410C",
  },
  statValue: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "mc-mark-narrow-medium",
  },
});

const coverStyles = StyleSheet.create({
  page: {
    flex: 1,
    alignItems: "center",
    padding: 30,
    backgroundColor: "#f3f4f6",
  },
  image: {
    height: 60,
    marginTop: 40,
  },
  title: {
    fontSize: 32,
    fontFamily: "mc-mark-narrow-medium",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 20,
  },
  date: {
    fontSize: 14,
    fontFamily: "mc-mark-narrow-medium",
    color: "#C2410C",
    textAlign: "center",
    marginBottom: 32,
  },
  filtersLabel: {
    fontSize: 14,
    fontFamily: "mc-mark-narrow-medium",
    // color: "#C2410C",
    textAlign: "center",
    marginBottom: 24,
  },
  keywordList: {
    fontFamily: "mc-mark-narrow-medium",
    fontSize: 14,
    textAlign: "center",
    lineHeight: 1.5,
  },
  copyright: {
    position: "absolute",
    fontSize: 9,
    fontFamily: "mc-mark-narrow-medium",
    color: "gray",
    bottom: 20,
    right: 20,
    // width: 100,
  },
  legendContainer: {
    position: "absolute",
    bottom: 40, // Adjust this value to move the legend up or down
    left: "50%",
    transform: "translateX(-50%)",
    flexDirection: "row",
    alignItems: "center",
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16, // spacing between legend items
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  legendText: {
    fontFamily: "mc-mark-narrow-medium",
    fontSize: 13,
  },
});

function proxiedImageUrl(data) {
  if (data.productImageUrl) {
    return (
      supabaseUrl +
      "/functions/v1/image-proxy?imageUrl=" +
      encodeURIComponent(data.productImageUrl)
    );
  } else {
    // null image (like coming soon)
    return imagePathForCategory(data.passionCategory);
  }
}

function proxiedImageUrlForCharts(url) {
  return (
    supabaseUrl +
    "/functions/v1/image-proxy?imageUrl=" +
    encodeURIComponent(url)
  );
}

const Legend = () => (
  <View style={coverStyles.legendContainer}>
    <View style={coverStyles.legendItem}>
      <Image src="/img/star.png" style={coverStyles.icon} />
      <Text style={coverStyles.legendText}>Iconic</Text>
    </View>
    <View style={coverStyles.legendItem}>
      <Image src="/img/seedling-solid.png" style={coverStyles.icon} />
      <Text style={coverStyles.legendText}>Eco-Friendly</Text>
    </View>
  </View>
);

const PDFProductCard = ({ data, user }) => {
  return (
    <View style={styles.card}>
      <View style={styles.imageContainer}>
        <Link src={data.productUrl}>
          <Image
            src={{
              uri: proxiedImageUrl(data),
              method: "GET",
              headers: { Authorization: `Bearer ${user.sessionToken}` },
              body: "",
            }}
            style={styles.productImage}
          />
        </Link>

        {data.status === "coming_soon" && (
          <Text style={styles.badge}>Coming Soon</Text>
        )}

        {data.endDate !== "2300-12-12" && (
          <Text style={styles.endDateLabel}>
            Until {dayjs(data.endDate).format("YYYY-MM-DD")}
          </Text>
        )}
      </View>

      <View style={styles.passionCategoryContainer}>
        <Text style={styles.category}>{data.passionCategory}</Text>
        <View style={styles.iconContainer}>
          {data.iconic && <Image src="/img/star.png" style={styles.icon} />}
          {data.ecoFriendly && (
            <Image src="/img/seedling-solid.png" style={styles.icon} />
          )}
        </View>
      </View>
      <Text style={styles.title}>{cleanString(data.title)}</Text>
      <View style={styles.locationContainer}>
        <Image src="/img/mapPin.png" style={styles.locationIcon} fixed />
        <Text style={styles.location}>
          {cleanString([data.city, data.country].filter(Boolean).join(", "))}
        </Text>
      </View>
      <View style={styles.price}>
        <Image src="/img/creditCardIcon.png" style={styles.priceIcon} fixed />

        <Text style={styles.priceText}>
          { PriceInformationAsString(data) }
        </Text>
      </View>
      <View style={styles.ptrContainer}>
        {data.passThroughApiRights === true ? (
          <Image
            src="/img/checkCircleIcon_green.png"
            style={styles.priceIcon}
            fixed
          />
        ) : (
          <Image
            src="/img/xCircleIcon_red.png"
            style={styles.priceIcon}
            fixed
          />
        )}
        <Text style={styles.priceText}>API PTR </Text>
        {data.passThroughCobrandRights === true ? (
          <Image
            src="/img/checkCircleIcon_green.png"
            style={styles.priceIcon}
            fixed
          />
        ) : (
          <Image
            src="/img/xCircleIcon_red.png"
            style={styles.priceIcon}
            fixed
          />
        )}
        <Text style={styles.priceText}>Co-brand PTR</Text>
      </View>

      <View style={styles.tagContainer}>
        <Text style={styles.tag}>{data.redemptionType}</Text>
        <Text style={styles.tag}>{data.contentType}</Text>
        {data.programName === "Live Nation" && (
          <Image
          src="/LN_circle_min.png"
          style={styles.liveNationIcon}
          fixed
        />
        )}
      </View>
    </View>
  );
};

function chunkArray(array, size) {
  const chunked = [];
  let index = 0;
  while (index < array.length) {
    chunked.push(array.slice(index, size + index));
    index += size;
  }
  return chunked;
}

const ProductPDF = ({ groupedProducts, user, searchParams, summary }) => {
  const paramObject = searchParams;

  console.log("searchParams: ", searchParams);

  const calculateLineOneString = () => {
    const parts = [
      paramObject.priceGroup && paramObject.priceGroup.length
        ? paramObject.priceGroup.join(" and ")
        : "",

      paramObject.globalAvailability && paramObject.globalAvailability.length
        ? paramObject.globalAvailability
            .join(" and ")
            ?.replace(/Available Globally/g, "Global")
        : "",

      paramObject.availabilityDate
        ? `Available on ${paramObject.availabilityDate}`
        : undefined,

      paramObject.ptr2 && paramObject.ptr2.length
        ? paramObject.ptr2
            .join(" and ")
            .replace(/PTR-API/g, "API PTR")
            .replace(/PTR-Cobrand/g, "Cobrand PTR")
            .replace(/,/g, " and ")
        : "",

      paramObject.contentStatus && paramObject.contentStatus.length
        ? paramObject.contentStatus.join(" and ")
        : "",
    ]
      .flat()
      .filter(Boolean);

    return parts.join(" | ");
  };

  const calculateLineTwoString = () => {
    return paramObject.countries && paramObject.countries.length > 0
      ? `Regions / Markets: ${paramObject.countries.join(", ")}`
      : undefined;
  };

  const calculateLineThreeString = () => {
    return paramObject.category && paramObject.category.length > 0
      ? `Passion Categories: ${paramObject.category.join(", ")}`
      : undefined;
  };

  const calculateLineFourString = () => {
    const parts = [
      paramObject.contentType?.join(", "),
      paramObject.redemptionType?.join(", "),
    ]
      .flat()
      .filter(Boolean);

    const resultString = parts.join(" | ");

    return resultString.length > 0
      ? `Content / Redemption Types: ${resultString}`
      : "";
  };

  const calculateLineFiveString = () => {
    return paramObject.subcategories && paramObject.subcategories.length > 0
      ? `Subcategories: ${paramObject.subcategories.join(", ")}`
      : undefined;
  };

  const calculateLineSixString = () => {
    return paramObject.languages && paramObject.languages.length > 0
      ? `Languages: ${paramObject.languages.join(", ")}`
      : undefined;
  };

  return (
    <Document>
      <Page
        key="coverPage"
        size="A4"
        orientation="landscape"
        style={coverStyles.page}
      >
        <Image
          source="./ma_priceless_standard_pos.png"
          style={coverStyles.image}
        />
        <Text style={coverStyles.title}>Content Catalog</Text>
        <Text style={coverStyles.date}>
          Exported: {dayjs().format("YYYY-MM-DD")}
        </Text>

        {calculateLineOneString().length > 0 && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineOneString()}
            </Text>
          </View>
        )}

        {calculateLineTwoString() && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineTwoString()}
            </Text>
          </View>
        )}

        {calculateLineThreeString() && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineThreeString()}
            </Text>
          </View>
        )}

        {calculateLineFourString().length > 0 && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineFourString()}
            </Text>
          </View>
        )}

        {calculateLineFiveString() && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineFiveString()}
            </Text>
          </View>
        )}

        {calculateLineSixString() && (
          <View>
            <Text style={coverStyles.filtersLabel}>
              {calculateLineSixString()}
            </Text>
          </View>
        )}

        <Legend />
        <Text style={styles.internalLabel}>For content planning use only</Text>
        <Text style={coverStyles.copyright} fixed>
          Copyright {new Date().getFullYear()} Mastercard
        </Text>
      </Page>

      {groupedProducts &&
        Object.entries(groupedProducts).map(([key, value], index) => (
          <React.Fragment key={index}>
            <Page
              key="coverPage"
              size="A4"
              orientation="landscape"
              style={gapPageStyles.page}
            >
              <Text style={gapPageStyles.title}>{key}</Text>
              <Text style={styles.internalLabel}>
                For content planning use only
              </Text>
              <Text
                style={styles.pageNumbers}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
              <Image src="/priceless_watermark.png" style={styles.logo} fixed />
            </Page>

            {chunkArray(value, 8).map((productChunk, index) => (
              <Page
                key={index}
                size="A4"
                orientation="landscape"
                style={styles.page}
              >
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {productChunk.map((product) => (
                    <PDFProductCard
                      key={product.id}
                      data={product}
                      user={user}
                    />
                  ))}
                </View>
                <Text style={styles.internalLabel}>
                  For content planning use only
                </Text>
                <Text
                  style={styles.pageNumbers}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
                <Image
                  src="/priceless_watermark.png"
                  style={styles.logo}
                  fixed
                />
              </Page>
            ))}
          </React.Fragment>
        ))}
    </Document>
  );
};

export default ProductPDF;

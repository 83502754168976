import {
  ShoppingCartIcon,
  MapPinIcon,
  CreditCardIcon,
  TicketIcon,
  ArrowRightOnRectangleIcon,
  HeartIcon,
  MegaphoneIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";

import { FaSeedling } from "react-icons/fa6";

import {
  BoltIcon,
  CheckCircleIcon,
  XCircleIcon,
  StarIcon,
} from "@heroicons/react/24/solid";

import { Tooltip } from "antd";
import { cleanString, formatCurrency } from "../utils";

export const PriceInformation = ({ data }) => {
  if (data.redemptionType.includes("Punch-out") && data.priceText) {
    let fullPriceString = data.priceText;

    if (data.priceText.includes("%num1%") && data.priceString1) {
      fullPriceString = fullPriceString.replace(
        "%num1%",
        formatCurrency(data.priceString1, data.currencyCode)
      );
    }
    if (data.priceText.includes("%num2%") && data.priceString2) {
      fullPriceString = fullPriceString.replace(
        "%num2%",
        formatCurrency(data.priceString2, data.currencyCode)
      );
    }
    return <span>{fullPriceString}</span>;
  } else {
    return (
      <>
        <span>{formatCurrency(data.price, data.currencyCode)}</span>
        {data.peopleCount > 0 && (
          <span>
            {" "}
            / {data.peopleCount} {data.peopleCount > 1 ? "people" : "person"}
          </span>
        )}
      </>
    );
  }
};

export const PriceInformationAsString = (data) => {
  let resultString = "";

  if (data.redemptionType.includes("Punch-out") && data.priceText) {
    let fullPriceString = data.priceText;

    if (data.priceText.includes("%num1%") && data.priceString1) {
      fullPriceString = fullPriceString.replace(
        "%num1%",
        formatCurrency(data.priceString1, data.currencyCode)
      );
    }
    if (data.priceText.includes("%num2%") && data.priceString2) {
      fullPriceString = fullPriceString.replace(
        "%num2%",
        formatCurrency(data.priceString2, data.currencyCode)
      );
    }

    fullPriceString = fullPriceString.replace("₹", "INR ");
    resultString = fullPriceString;
  } else {
    resultString = formatCurrency(data.price, data.currencyCode).replace(
      "₹",
      "INR "
    );

    if (data.peopleCount > 0) {
      resultString += ` / ${data.peopleCount} ${
        data.peopleCount > 1 ? "people" : "person"
      }`;
    }
  }

  return resultString;
};

function RedemptionIcon({ type }) {
  switch (type) {
    case "Auction":
      return (
        <MegaphoneIcon className="text-[#b91c1c] align-top w-5 h-5 inline-block mr-1" />
      );
    case "Check-out":
      return (
        <ShoppingCartIcon className="text-[#b91c1c] align-top w-5 h-5 inline-block mr-1" />
      );
    case "Punch-out":
      return (
        <ArrowRightOnRectangleIcon className="text-[#b91c1c] align-top w-5 h-5 inline-block mr-1" />
      );
    case "Sweepstake":
      return (
        <TicketIcon className="text-[#b91c1c] align-top w-5 h-5 inline-block mr-1" />
      );
    case "Donation":
      return (
        <HeartIcon className="text-[#b91c1c] align-top w-5 h-5 inline-block mr-1" />
      );
    default:
      return <div />;
  }
}

export function imagePathForCategory(type) {
  switch (type) {
    case "Arts and Culture":
      return "./img/arts_culture.jpg";
    case "Culinary":
      return "./img/culinary.jpg";
    case "Entertainment":
      return "./img/entertainment.jpg";
    case "Shopping":
      return "./img/shopping.jpg";
    case "Sports":
      return "./img/sports.jpg";
    case "Travel":
      return "./img/travel.jpg";
    case "Health and Wellness":
      return "./img/health_wellness.jpg";
    default:
      return "";
  }
}

export function imagePathForData(data) {
  if (data.productImageUrl) {
    const lowResUrl = data.productImageUrl.replace(/(\.[\w\d_-]+)$/, "__S$1");
    return lowResUrl;
  } else {
    return imagePathForCategory(data.passionCategory);
  }
}

const ProductCard = ({ data, onClick }) => {
  return (
    <div
      className="flex flex-col h-full bg-white shadow-lg rounded-lg hover:bg-orange-50/50 overflow-hidden"
      onClick={onClick}
    >
      <div className="relative">
        <img
          key={data.id}
          src={imagePathForData(data)}
          alt={data.title}
          loading="lazy"
          className="w-full object-cover rounded-t-lg h-28"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "priceless_placeholder2.png";
          }}
        />

        {data.status === "coming_soon" && (
          <span className="inline-flex mcfont uppercase items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-semibold text-yellow-800 ring-1 ring-inset ring-yellow-600/20 absolute bottom-2 left-2 opacity-95">
            <ClockIcon className="align-top w-4 h-4 inline-block mr-1 " />
            Coming Soon
          </span>
        )}
        {data.status === "live" && (
          <span className="inline-flex mcfont uppercase items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 absolute bottom-2 left-2 opacity-95">
            <BoltIcon className="align-top w-4 h-4 inline-block mr-1 " />
            Live
          </span>
        )}
      </div>
      <div className="p-4 flex flex-col justify-between h-full">
        <div>
          <div className="uppercase mcfont tracking-wide text-xs text-orange-600 font-semibold">
            {data.passionCategory}
          </div>
          <div
            className="product-title mt-2 text-lg mcfont font-semibold text-black hover:underline"
            title={cleanString(data.title)}
          >
            <Tooltip
              title={
                <div className="mcfont" style={{ textAlign: "center" }}>
                  Iconic experience
                </div>
              }
            >
              {data.iconic && (
                <StarIcon
                  className="mb-1 align-middle w-5 h-5 inline-block mr-1 "
                  style={{ fill: "gold" }}
                />
              )}
            </Tooltip>
            <Tooltip
              title={
                <div className="mcfont" style={{ textAlign: "center" }}>
                  Eco-friendly
                </div>
              }
            >
              {data.ecoFriendly && (
                <FaSeedling
                  className="mb-1 align-middle w-5 h-5 inline-block mr-1 "
                  style={{ fill: "green" }}
                />
              )}
            </Tooltip>
            {cleanString(data.title)}
          </div>

          {// Checks if the joined string is not empty after filtering out falsy values
          [data.city, data.country]
            .filter(Boolean)
            .join(", ")
            .trim() && (
            <div className="mt-2 text-gray-800 font-semibold mcfont relative">
              <MapPinIcon className="text-[#b91c1c] align-top w-5 h-5 absolute left-0" />
              <p className="pl-6">
                {cleanString(
                  [data.city, data.country].filter(Boolean).join(", ")
                )}
              </p>
            </div>
          )}

          <p className="mt-3 text-gray-800 font-semibold mcfont">
            <CreditCardIcon className="text-[#b91c1c] align-top w-5 h-5 inline-block mr-1 " />
            <PriceInformation data={data} />
          </p>

          <p className="mt-3 text-gray-800 font-semibold mcfont">
            <RedemptionIcon type={data.redemptionType} />
            {data.redemptionType}
          </p>

          <p className="mt-3 mb-4 text-gray-800 font-medium mcfont">
            {data.passThroughApiRights === true ? (
              <CheckCircleIcon
                className="align-top w-5 h-5 inline-block mr-1 "
                style={{ fill: "green" }}
              />
            ) : (
              <XCircleIcon
                className="align-top w-5 h-5 inline-block mr-1 "
                style={{ fill: "#b91c1c" }}
              />
            )}
            API PTR
            {data.passThroughCobrandRights === true ? (
              <CheckCircleIcon
                className="ml-3 align-top w-5 h-5 inline-block mr-1 "
                style={{ fill: "green" }}
              />
            ) : (
              <XCircleIcon
                className="ml-3 align-top w-5 h-5 inline-block mr-1 "
                style={{ fill: "#b91c1c" }}
              />
            )}
            Co-brand PTR
          </p>
        </div>

        <div className="mt-auto flex justify-between items-center">
          {data.contentType === "EPP Offer" ? (
            <Tooltip
              title={
                <div style={{ textAlign: "center" }}>
                  European Privileges Program
                </div>
              }
            >
              <span className="inline-block mt-1 uppercase items-center mcfont rounded-full bg-orange-50 px-2 py-1 text-xs font-semibold text-orange-800 ring-1 ring-inset ring-orange-600/20">
                {data.contentType}
              </span>
            </Tooltip>
          ) : (
            <span className="inline-block mt-1 uppercase items-center mcfont rounded-full bg-orange-50 px-2 py-1 text-xs font-semibold text-orange-800 ring-1 ring-inset ring-orange-600/20">
              {data.contentType}
            </span>
          )}
          {data.programName === "Live Nation" && (
            <img
              src="/LN_circle_min.png"
              alt="Badge"
              className="ml-auto w-11 h-11"
            />
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default ProductCard;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Card, Col, Row, Input, Button, Modal, Tooltip } from "antd";
import dayjs from "dayjs";
import { supabase } from "../../supabaseClient";
import {
  ArrowDownTrayIcon,
  FlagIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";

import { convertToCSV, classNames } from "../../utils";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { SearchOutlined } from "@ant-design/icons";
import { useUser, isAdmin } from "../../UserProvider";
import ComingSoonInfoView from "./ComingSoonInfoView";

import { Helmet } from "react-helmet";
import { Switch } from "@headlessui/react";

const MySubmissions = () => {
  const location = useLocation();
  const { user } = useUser();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  // One data object, passed into info view modal
  const [selectedRowData, setSelectedRowData] = useState(null);

  // Used for bulk deletion
  const [checkedRowKeys, setCheckedRowKeys] = useState([]);

  const rowSelection = {
    checkedRowKeys,
    onChange: (checkedRowKeys) => {
      setCheckedRowKeys(checkedRowKeys);
    },
  };

  const navigate = useNavigate();
  const [showAllContent, setShowAllContent] = useState(false);
  const [showFlagged, setShowFlagged] = useState(false);

  const [tablePageSize, setTablePageSize] = useState(15);

  const [reload, setReload] = useState(false);

  // Runs once on initial component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async ({
      category,
      contentType,
      countries,
      isAlwaysOn,
      isDateDriven,
      isIconic,
      redemptionType,
      subcategories,
    }) => {
      // Ensure we have a user session_token
      if (!user.sessionToken) {
        console.error("Error fetching content: No session token");
        alert(
          "An error occurred while fetching content. Please refresh and try again."
        );
      }

      // Can assume we have a session token.
      const { data, error } = await supabase.functions.invoke(
        "pipeline-getAll",
        {
          body: {
            category: category,
            contentType: contentType,
            countries: countries,
            isAlwaysOn: isAlwaysOn,
            isDateDriven: isDateDriven,
            isIconic: isIconic,
            redemptionType: redemptionType,
            subcategories: subcategories,
            showMyContentOnly: !showAllContent,
          },
          headers: { Authorization: `Bearer ${user.sessionToken}` },
        }
      );

      if (error) {
        console.error("Error fetching data:", error);
      } else if (data.error) {
        console.error("Error fetching data row:", data.error);
      } else {
        console.log(data.data);
        const defaultSorted = data.data.sort(
          (a, b) => new Date(a.publishDate) - new Date(b.publishDate)
        );
        setData(defaultSorted);
      }
      setLoading(false);
    };

    const searchParams = new URLSearchParams(location.search);

    const category = searchParams.get("category")?.split(",");
    const contentStatus = searchParams.get("contentStatus");
    const contentType = searchParams.get("contentType")?.split(",");
    const countries = searchParams.get("countries")?.split(",");

    let isAlwaysOn;
    let isDateDriven;
    switch (searchParams.get("alwaysOnDateDriven")) {
      case "Always On":
        isAlwaysOn = true;
        isDateDriven = false;
        break;
      case "Date Driven":
        isAlwaysOn = false;
        isDateDriven = true;
        break;
      default:
        isAlwaysOn = undefined;
        isDateDriven = undefined;
    }

    let isIconic;
    switch (searchParams.get("isIconic")) {
      case "yes":
        isIconic = true;
        break;
      case "no":
        isIconic = false;
        break;
      default:
        isIconic = undefined;
    }

    const mySubmissionsOnly = searchParams.get("mySubmissionsOnly") === "true";
    const redemptionType = searchParams.get("redemptionType")?.split(",");
    const subcategories = searchParams.get("subcategories")?.split(",");

    const params = {
      category,
      contentStatus,
      contentType,
      countries,
      isAlwaysOn,
      isDateDriven,
      isIconic,
      mySubmissionsOnly,
      redemptionType,
      subcategories,
    };

    setLoading(true);
    fetchData(params);
  }, [location.search, user.sessionToken, reload, showAllContent]);

  const downloadCSV = () => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    const date = dayjs().format("YYYY-MM-DDTHH:mm");
    a.download = `my_submissions_${date}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const stringSorter = (key) => (a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (!valueA && !valueB) return 0; // Both are null/undefined
    if (!valueA) return -1; // Only a is null/undefined
    if (!valueB) return 1; // Only b is null/undefined

    return String(valueA).localeCompare(String(valueB));
  };

  const handleTableChange = (pagination) => {
    setTablePageSize(pagination.pageSize);
  };

  const handleEdit = (event, record) => {
    event.stopPropagation();
    navigate("/update", { state: record });
  };

  const handleDelete = (event, record) => {
    event.stopPropagation();
    let deleteReason = "";

    Modal.confirm({
      title: "Confirm Deletion?",
      okText: "Delete",
      cancelText: "Never mind",
      content: (
        <>
          <p className="block text-sm font-medium leading-6 text-gray-900">
            Are you sure you want to delete '{record.title}'?
          </p>

          <input
            type="text"
            required
            onChange={(e) => (deleteReason = e.target.value)}
            className="block w-full mb-2 mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
            placeholder="Please provide the deletion reason"
          />
        </>
      ),
      onOk: async () => {
        // Can assume we have a session token.
        const { data, error } = await supabase.functions.invoke(
          "pipeline-bulk-delete",
          {
            body: { ids: [record.id], reason: deleteReason },
            headers: { Authorization: `Bearer ${user.sessionToken}` },
          }
        );

        let log = JSON.stringify({
          content_id: record.id,
          data: data,
          error: error,
        });

        supabase.functions.invoke("pipeline-log", {
          body: { event: "DELETE", body: log },
          headers: { Authorization: `Bearer ${user.sessionToken}` },
        });

        if (error) {
          console.error("Error deleting data:", error);
        } else if (data.error) {
          console.error("Error deleting:", data.error);
        } else {
          // Deletion success!
          console.log(data.data);
        }

        handleModalClose();
        // console.log("Triggering re-load of table...");
        setReload(!reload);
      },
    });
  };

  const columns = [
    {
      title: "",
      dataIndex: "flagged",
      render: (flagged) =>
        flagged && flagged === true ? (
          <Tooltip
            title={
              <div style={{ textAlign: "center" }}>
                You have flagged this content for further review.
              </div>
            }
          >
            <FlagIcon
              className="h-5 w-5 text-red-600 cursor-pointer"
              aria-hidden="true"
            />
          </Tooltip>
        ) : (
          ""
        ),
    },
    {
      title: "id",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Submitted Date",
      dataIndex: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (text) => (text ? dayjs(text).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: stringSorter("title"),
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Merchant",
      dataIndex: "merchantName",
      sorter: stringSorter("merchantName"),
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Type",
      dataIndex: "contentType",
      sorter: stringSorter("contentType"),
      render: (text) => (text ? text : "-"),
      filters: [
        {
          text: "In-Person Experience",
          value: "In-Person Experience",
        },
        {
          text: "Digital Experience",
          value: "Digital Experience",
        },
        {
          text: "Editorial Content",
          value: "Editorial Content",
        },
        {
          text: "Offer",
          value: "Offer",
        },
        {
          text: "Physical Product",
          value: "Physical Product",
        },
      ],
      onFilter: (value, record) => {
        if (!record.contentType) {
          return false;
        }
        return record.contentType.localeCompare(value) === 0;
      },
    },
    {
      title: "Category",
      dataIndex: "passionCategory",
      sorter: stringSorter("passionCategory"),
      render: (text) => text || "-",
      filters: [
        {
          text: "Arts and Culture",
          value: "Arts and Culture",
        },
        {
          text: "Culinary",
          value: "Culinary",
        },
        {
          text: "Music and Entertainment",
          value: "Entertainment",
        },
        {
          text: "Fashion and Shopping",
          value: "Fashion and Shopping",
        },
        {
          text: "Sports",
          value: "Sports",
        },
        {
          text: "Travel",
          value: "Travel",
        },
        {
          text: "Health and Wellness",
          value: "Health and Wellness",
        },
      ],
      onFilter: (value, record) => {
        if (!record.passionCategory) {
          return false;
        }
        return record.passionCategory.localeCompare(value) === 0;
      },
    },
    {
      title: "Country",
      dataIndex: "countryContentTakesPlace",
      sorter: stringSorter("countryContentTakesPlace"),
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Estimated Publish Date",
      dataIndex: "publishDate",
      width: 130,
      sorter: (a, b) => new Date(a.publishDate) - new Date(b.publishDate),
      render: (text) => {
        const currentDate = new Date();
        const itemDate = new Date(text);
        const isOlder = itemDate < currentDate;

        return isOlder ? (
          <Tooltip
            title={
              <div style={{ textAlign: "center" }}>
                If this content has been published in production, please remove
                it here.
              </div>
            }
          >
            <div
              style={{ fontWeight: "bold" }}
              className="text-red-700 cursor-pointer"
            >
              {text ? dayjs(text).format("YYYY-MM-DD") : "-"}
            </div>
          </Tooltip>
        ) : text ? (
          dayjs(text).format("YYYY-MM-DD")
        ) : (
          "-"
        );
      },
    },
    {
      title: "Actions",
      key: "details",
      render: (_value, record) => {
        if (isAdmin(user) || record.user_id === user.id) {
          return (
            <div className="flex items-center space-x-4">
              <Tooltip title="Edit">
                <button
                  type="button"
                  onClick={(e) => handleEdit(e, record)}
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-orange-50 px-3 py-2 text-sm font-medium text-orange-600 shadow-sm hover:bg-orange-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PencilSquareIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>

              <Tooltip title="Delete">
                <button
                  type="button"
                  onClick={(e) => handleDelete(e, record)}
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-red-50 px-3 py-2 text-sm font-medium text-red-600 shadow-sm hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                </button>
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];

  const handleShowDetails = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      (showFlagged ? item.flagged : true) &&
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const rowClassName = (record, index) => {
    const publishDate = new Date(record.publishDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    publishDate.setHours(0, 0, 0, 0);

    if (publishDate.getTime() < today.getTime()) {
      return "outdated-row";
    } else {
      return index % 2 === 0 ? "table-row-light" : "table-row-dark";
    }
  };

  const handleBulkDelete = () => {
    let deleteReason = "";

    Modal.confirm({
      title: "Confirm Deletion?",
      okText: "Delete",
      cancelText: "Never mind",
      content: (
        <>
          <p className="block text-sm font-medium leading-6 text-gray-900">
            Are you sure you want to delete the following items:{" "}
            {checkedRowKeys.join(", ")}?
          </p>

          <input
            type="text"
            required
            onChange={(e) => (deleteReason = e.target.value)}
            className="block w-full mb-2 mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
            placeholder="Please provide the deletion reason"
          />
        </>
      ),
      onOk: async () => {
        // Can assume we have a session token.
        console.log("checked rows are: ", checkedRowKeys);

        const { data, error } = await supabase.functions.invoke(
          "pipeline-bulk-delete",
          {
            body: { ids: checkedRowKeys, reason: deleteReason },
            headers: { Authorization: `Bearer ${user.sessionToken}` },
          }
        );

        let log = JSON.stringify({
          content_id: checkedRowKeys,
          data: data,
          error: error,
        });

        supabase.functions.invoke("pipeline-log", {
          body: { event: "DELETE", body: log },
          headers: { Authorization: `Bearer ${user.sessionToken}` },
        });

        if (error) {
          console.error("Error deleting data:", error);
        } else if (data.error) {
          console.error("Error deleting:", data.error);
        } else {
          // Deletion success!
          console.log(data.data);
        }

        setCheckedRowKeys([]); // Clear selection

        // console.log("Triggering re-load of table...");
        setReload(!reload);
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>My Submissions | Priceless Content Catalog</title>
      </Helmet>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="md:flex md:items-center md:justify-between mb-8">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold mcfont leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                My Content Submissions
              </h2>
            </div>

            <div className="flex items-center space-x-5">
              {checkedRowKeys.length > 0 && (
                <button
                  type="button"
                  onClick={handleBulkDelete}
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-red-50 px-3 py-2 text-sm font-medium text-red-600 shadow-sm hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />{" "}
                  Delete Selected Rows
                </button>
              )}

              <Switch.Group>
                <div className="flex items-center">
                  <Switch.Label className="flex items-center mr-4 mcfont font-bold text-sm text-gray-700 ">
                    <Tooltip
                      placement="bottom"
                      title={
                        <div style={{ textAlign: "center" }}>
                          View ALL user submissions, including your own.
                        </div>
                      }
                    >
                      <QuestionMarkCircleIcon
                        className="-ml-0.5 mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </Tooltip>
                    Show all user content:
                  </Switch.Label>

                  <Switch
                    checked={showAllContent}
                    onChange={setShowAllContent}
                    className={`${
                      showAllContent ? "bg-orange-600" : "bg-gray-200"
                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                  >
                    <span
                      className={`${
                        showAllContent ? "translate-x-6" : "translate-x-1"
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                  </Switch>
                </div>
              </Switch.Group>

              {data.length > 0 && (
                <div className=" md:justify-end">
                  <Switch.Group>
                    <div className="flex items-center">
                      <Switch.Label className="flex items-center mr-4 mcfont font-bold text-sm text-gray-700 ">
                        Show flagged content only
                      </Switch.Label>

                      <Switch
                        checked={showFlagged}
                        onChange={setShowFlagged}
                        className={`${
                          showFlagged ? "bg-orange-600" : "bg-gray-200"
                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                      >
                        <span
                          className={`${
                            showFlagged ? "translate-x-6" : "translate-x-1"
                          } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                </div>
              )}
            </div>
          </div>

          <Row gutter={[16, 16]}>
            <Col xl={6} lg={24} xs={24}>
              <Card title="Filter by Text" className="shadow-sm">
                <Input
                  placeholder="Title, description, merchant..."
                  onChange={handleSearch}
                  allowClear
                  prefix={<SearchOutlined />}
                />

                <div className="mt-5 italic text-center text-sm text-grey-700">
                  <p>
                    Select a row to view full content details. Dates use
                    YYYY-MM-DD format.
                  </p>
                </div>
              </Card>

              {/* Only display download if user is an admin */}
              {isAdmin(user) && data.length > 0 && (
                <Row
                  type="flex"
                  justify="center"
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <button
                    type="button"
                    onClick={downloadCSV}
                    className="inline-flex items-center mr-10 gap-x-1.5 rounded-md bg-orange-50 px-3 py-2 text-sm font-medium text-orange-600 shadow-sm hover:bg-orange-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <ArrowDownTrayIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    Download as CSV
                  </button>
                </Row>
              )}
            </Col>
            <Col xl={18} xs={24}>
              <Table
                rowSelection={rowSelection}
                columns={columns}
                className="rounded-md shadow-md"
                size={"middle"}
                loading={loading}
                rowClassName={rowClassName}
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  pageSize: tablePageSize,
                }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleShowDetails(record);
                    },
                  };
                }}
                onChange={handleTableChange}
                locale={{
                  emptyText: "No content submissions were found.",
                }}
                dataSource={filteredData}
                rowKey="id"
              />
            </Col>
            <Modal
              title={
                selectedRowData && (
                  <div className="mcfont text-lg">{selectedRowData.title}</div>
                )
              }
              open={isModalVisible}
              width={700}
              onCancel={handleModalClose}
              footer={[
                <Button key="back" onClick={handleModalClose}>
                  Return
                </Button>,
              ]}
            >
              {selectedRowData && (
                <div>
                  <ComingSoonInfoView
                    data={selectedRowData}
                    deleteHandler={handleDelete}
                  />
                </div>
              )}
            </Modal>
          </Row>
        </div>
      </main>
    </>
  );
};

export default MySubmissions;

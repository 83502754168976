import { useFormContext, Controller } from "react-hook-form";
import { useState, useEffect } from 'react'
import { currencies } from "../../data/currencies";
import { DatePicker } from "antd";

const binaryOptions = [
  { id: 'yes', title: 'Yes' },
  { id: 'no', title: 'No' },
]

const alwaysOnOptions = [
  { id: 'Always On', title: 'Always On' },
  { id: 'Single Date', title: 'Date Driven with one bookable date' },
  { id: 'Multiple Dates', title: 'Date Driven with multiple bookable dates' },
]

function ContentOverviewBlock({data}) {

  const { control, register, watch, formState: { errors } } = useFormContext();

  const [probability, setProbability] = useState(50);
  const isInventoryReserved = watch("isInventoryReserved");
  const publishDate = watch('publishDate');

  useEffect(() => {
    window.scrollTo(0, 0)

    if (data) {
      setProbability(data.goLiveProbability);
    }
  }, [data])

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">

      <div>
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div>
              <h3 className="text-md font-semibold text-yellow-800">Content Overview</h3>
              <div className="mb-3 mt-2 text-sm text-yellow-700">
                <p>
                  Please provide a basic overview of your content. This will be used to help users find your content.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">


        <div className="col-span-full">
          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
            Title
          </label>
          <div className="mt-2">
            <input {...register("title", { required: true })}
              type="text"
              name="title"
              id="title"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
              placeholder="One sentence describing this content"
            />

            {errors.title &&
              <p className="mt-2 text-sm text-red-600">
                Title is required
              </p>
            }

          </div>
        </div>

        <div className="col-span-full">
          <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
            Short Description
          </label>
          <div className="mt-2">
            <textarea {...register("description", { required: true })}
              id="description"
              name="description"
              rows={3}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
              defaultValue={''}
              placeholder='Write two to three sentences describing this content with more details and include the priceless elements.'
            />

            {errors.description &&
              <p className="mt-2 text-sm text-red-600">
                Short description is required
              </p>
            }
          </div>
        </div>

        <div className="col-span-full">
          <label htmlFor="merchant" className="block text-sm font-medium leading-6 text-gray-900">
            Merchant Name
          </label>
          <div className="mt-2">
            <input {...register("merchant", { required: true })}
              type="text"
              name="merchant"
              id="merchant"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
              placeholder="Name of the merchant provider"
            />
            {errors.merchant &&
              <p className="mt-2 text-sm text-red-600">
                Merchant name is required
              </p>
            }
          </div>
        </div>

        <div className="sm:col-span-3 sm:col-start-1">
          <label htmlFor="publishDate" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
            Estimated Publish Date
          </label>

          <Controller
              name="publishDate"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  size="large"
                  className="w-48"
                  value={field.value}
                  onChange={(values) => field.onChange(values)}
                />
              )}
            />

          {errors.publishDate &&
            <p className="mt-2 text-sm text-red-600">
              Estimated publish date is required
            </p>
          }
        </div>


        <div className="sm:col-span-3">
          <label htmlFor="closeDate" className="block text-sm font-medium leading-6 text-gray-900">
            Estimated Sales Close Date
          </label>

          <Controller
            name="closeDate"
            rules={{ 
              required: "Estimated sales close date is required",
              validate: value => !publishDate || new Date(value) > new Date(publishDate) || "Sales Close Date must be after the Publish Date"
            }}
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                size="large"
                className="w-48"
                value={field.value}
                onChange={(values) => field.onChange(values)}
              />
            )}
          />

          {errors.closeDate &&
            <p className="mt-2 text-sm text-red-600">
              {errors.closeDate.message}
            </p>
          }
        </div>


        <div className="col-span-full mt-1">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Is this content always on or date driven?
          </label>

          <fieldset className="mt-4">

            <div className="space-y-4">
              {alwaysOnOptions.map((option) => (
                <div key={option.id} className="flex items-center">
                  <input {...register("alwaysOnDateDriven", { required: true })}
                    id={option.id}
                    type="radio"
                    value={option.id}
                    className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-300"
                  />
                  <label htmlFor={option.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {option.title}
                  </label>
                </div>
              ))}
              {errors.alwaysOnDateDriven &&
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  Always on / date driven status is required
                </p>
              }
            </div>
          </fieldset>

        </div>

        <div className="sm:col-span-2 sm:col-start-1">
          <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
            Price
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              {/* <span className="text-gray-500 sm:text-sm">$</span> */}
            </div>
            <input {...register("price", { required: true })}
              type="number"
              step="0.01"
              name="price"
              id="price"
              className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
              placeholder="0.00"
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <select {...register("currency")}
                id="currency"
                name="currency"
                className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm"
              >

                {currencies.map((currency) => <option key={currency.code} value={currency.code}>{currency.code}</option>)}
              </select>

            </div>
          </div>

          {errors.price &&
            <p className="mt-2 text-sm text-red-600">
              Price is required (enter 0 if free)
            </p>
          }
        </div>


        <div className="col-span-full">
          <label htmlFor="peopleCount" className="block text-sm font-medium leading-6 text-gray-900">
            Number of People
          </label>
          <p className="text-sm text-gray-500">How many people does 1 unit of this content accommodate at the above price?</p>

          <div className="mt-2 w-52">
            <input {...register("peopleCount", { required: true })}
              type="number"
              name="peopleCount"
              id="peopleCount"
              min="0"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
              placeholder="2"
            />
          </div>

          {errors.peopleCount &&
            <p className="mt-2 text-sm text-red-600">
              Number of people value is required
            </p>
          }
        </div>

        <div className="sm:col-span-3 sm:col-start-1">
          <label htmlFor="initialInventory" className="block text-sm font-medium leading-6 text-gray-900">
            Initial Inventory Count
          </label>
          <div className="mt-2 w-52">
            <input {...register("initialInventory")}
              type="number"
              name="initialInventory"
              id="initialInventory"
              min="0"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
              placeholder="250"
            />
          </div>
        </div>


        <div className="sm:col-span-3">
          <label htmlFor="maxQuantity" className="block text-sm font-medium leading-6 text-gray-900">
            Max Purchase Quantity per Order
          </label>
          <div className="mt-2 w-52">
            <input {...register("maxQuantity")}
              type="number"
              name="maxQuantity"
              id="maxQuantity"
              min="0"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
              placeholder="4"
            />
          </div>
        </div>


        <div className="col-span-full">
          <label htmlFor="redemptionType" className="block text-sm font-medium leading-6 text-gray-900">
            Redemption Mechanism
          </label>
          <select {...register("redemptionType", { required: true })}
            id="redemptionType"
            name="redemptionType"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-300 sm:text-sm sm:leading-6"
            defaultValue=""
          >
            <option value="" selected disabled hidden>Select redemption type...</option>
            <option>Auction</option>
            <option>Check-out</option>
            <option>Punch-out</option>
            <option>Sweepstakes</option>
            <option>Donation</option>
          </select>

          {errors.redemptionType &&
            <p className="mt-2 text-sm text-red-600">
              Redemption mechanism is required
            </p>
          }
        </div>

        <div className='col-span-full'>

          <label htmlFor="goLiveProbability" className="block text-sm font-medium leading-6 text-gray-900">
            Probability of going live on Priceless.com: {probability}%
          </label>
          <input {...register("goLiveProbability", { required: true })}
            type="range"
            className="accent-[#CF4500] transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-200"
            id="goLiveProbability"
            name="goLiveProbability"
            step="10"
            onChange={(event) => setProbability(event.target.value)}
          />

          {errors.goLiveProbability &&
            <p className="mt-2 text-sm text-red-600">
              Go-live probability is required
            </p>
          }
        </div>


        <div className='col-span-full'>

          <label htmlFor="isReservedInventory" className="block text-sm font-medium leading-6 text-gray-900">
            Reserved Inventory
          </label>
          <p className="text-sm text-gray-500">Is any inventory reserved for specific purposes?</p>
          <fieldset className="mt-4">
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {binaryOptions.map((option) => (
                <div key={option.id} className="flex items-center">
                  <input {...register("isInventoryReserved", { required: true })}
                    id={option.id}
                    type="radio"
                    value={option.id}
                    className="h-4 w-4 border-gray-300 text-orange-400 focus:ring-orange-200"
                  />
                  <label htmlFor={option.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {option.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>

          {errors.isInventoryReserved &&
            <p className="mt-2 text-sm text-red-600">
              Reserved inventory status required
            </p>
          }

        </div>

        {isInventoryReserved == "yes" && (
          <div className="col-span-full">
            <label htmlFor="reservedInventoryDetails" className="block text-sm font-normal leading-6 text-gray-900">
              Please specify the number of assets and the entities for which inventory is reserved.
            </label>
            <div className="mt-2">
              <textarea {...register("reservedInventoryDetails", { required: true })}
                id="reservedInventoryDetails"
                rows={2}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6"
                defaultValue={''}
                placeholder='For example, X number of inventory reserved for local country, Y number of inventory reserved for B2B2C partners, etc. Please specify the names of the B2B2C partners.'
              />
            </div>

            {errors.reservedInventoryDetails &&
              <p className="mt-2 text-sm text-red-600">
                Reserved inventory details are required
              </p>
            }

          </div>
        )}



      </div>
    </div>
  )
}

export default ContentOverviewBlock;

export const countries = [
  {
    "label": "Argentina",
    "value": "Argentina"
  },
  {
    "label": "Australia",
    "value": "Australia"
  },
  {
    "label": "Austria",
    "value": "Austria"
  },
  {
    "label": "Brazil",
    "value": "Brazil"
  },
  {
    "label": "Bulgaria",
    "value": "Bulgaria"
  },
  {
    "label": "Canada",
    "value": "Canada"
  },
  {
    "label": "Chile",
    "value": "Chile"
  },
  {
    "label": "China",
    "value": "China"
  },
  {
    "label": "Colombia",
    "value": "Colombia"
  },
  {
    "label": "Croatia",
    "value": "Croatia"
  },
  {
    "label": "Czechia",
    "value": "Czechia"
  },
  {
    "label": "Ecuador",
    "value": "Ecuador"
  },
  {
    "label": "Fiji",
    "value": "Fiji"
  },
  {
    "label": "France",
    "value": "France"
  },
  {
    "label": "Germany",
    "value": "Germany"
  },
  {
    "label": "Greece",
    "value": "Greece"
  },
  {
    "label": "Hong Kong",
    "value": "Hong Kong"
  },
  {
    "label": "India",
    "value": "India"
  },
  {
    "label": "Indonesia",
    "value": "Indonesia"
  },
  {
    "label": "Ireland",
    "value": "Ireland"
  },
  {
    "label": "Italy",
    "value": "Italy"
  },
  {
    "label": "Japan",
    "value": "Japan"
  },
  {
    "label": "Kenya",
    "value": "Kenya"
  },
  {
    "label": "Macau",
    "value": "Macau"
  },
  {
    "label": "Malaysia",
    "value": "Malaysia"
  },
  {
    "label": "Maldives",
    "value": "Maldives"
  },
  {
    "label": "Mexico",
    "value": "Mexico"
  },
  {
    "label": "Netherlands",
    "value": "Netherlands"
  },
  {
    "label": "New Zealand",
    "value": "New Zealand"
  },
  {
    "label": "Norway",
    "value": "Norway"
  },
  {
    "label": "Puerto Rico",
    "value": "Puerto Rico"
  },
  {
    "label": "Romania",
    "value": "Romania"
  },
  {
    "label": "Serbia",
    "value": "Serbia"
  },
  {
    "label": "Singapore",
    "value": "Singapore"
  },
  {
    "label": "South Africa",
    "value": "South Africa"
  },
  {
    "label": "South Korea",
    "value": "Korea"
  },
  {
    "label": "Spain",
    "value": "Spain"
  },
  {
    "label": "Sweden",
    "value": "Sweden"
  },
  {
    "label": "Taiwan",
    "value": "Taiwan"
  },
  {
    "label": "Thailand",
    "value": "Thailand"
  },
  {
    "label": "Turkey",
    "value": "Turkey"
  },
  {
    "label": "United Arab Emirates",
    "value": "United Arab Emirates"
  },
  {
    "label": "United Kingdom",
    "value": "United Kingdom"
  },
  {
    "label": "United States",
    "value": "United States"
  },
  {
    "label": "Uruguay",
    "value": "Uruguay"
  }
]
export const MarketsData = [
  {
    title: "Europe",
    value: "Europe",
    children: [
      {
        title: "Austria",
        value: "Austria",
      },
      {
        title: "Bulgaria",
        value: "Bulgaria",
      },
      {
        title: "Croatia",
        value: "Croatia",
      },
      {
        title: "Czechia",
        value: "Czechia",
      },
      {
        title: "France",
        value: "France",
      },
      {
        title: "Germany",
        value: "Germany",
      },
      {
        title: "Greece",
        value: "Greece",
      },
      {
        title: "Ireland",
        value: "Ireland",
      },
      {
        title: "Italy",
        value: "Italy",
      },
      {
        title: "Netherlands",
        value: "Netherlands",
      },
      {
        title: "Norway",
        value: "Norway",
      },
      {
        title: "Romania",
        value: "Romania",
      },
      {
        title: "Serbia",
        value: "Serbia",
      },
      {
        title: "Spain",
        value: "Spain",
      },
      {
        title: "Sweden",
        value: "Sweden",
      },
      {
        title: "Turvalue",
        value: "Turvalue",
      },
      {
        title: "United Kingdom",
        value: "United Kingdom",
      },
    ],
  },
  {
    title: "Middle East & Africa",
    value: "Middle East & Africa",
    children: [
      {
        title: "Kenya",
        value: "Kenya",
      },
      {
        title: "South Africa",
        value: "South Africa",
      },
      {
        title: "United Arab Emirates",
        value: "United Arab Emirates",
      },
    ],
  },
  {
    title: "North America",
    value: "North America",
    children: [
      {
        title: "Canada",
        value: "Canada",
      },
      {
        title: "United States",
        value: "United States",
      },
    ],
  },
  {
    title: "Latin America & Caribbean",
    value: "Latin America & Caribbean",
    children: [
      {
        title: "Argentina",
        value: "Argentina",
      },
      {
        title: "Brazil",
        value: "Brazil",
      },
      {
        title: "Chile",
        value: "Chile",
      },
      {
        title: "Colombia",
        value: "Colombia",
      },
      {
        title: "Ecuador",
        value: "Ecuador",
      },
      {
        title: "Mexico",
        value: "Mexico",
      },
      {
        title: "Puerto Rico",
        value: "Puerto Rico",
      },
      {
        title: "Uruguay",
        value: "Uruguay",
      },
    ],
  },
  {
    title: "Asia Pacific",
    value: "Asia Pacific",
    children: [
      {
        title: "Australia",
        value: "Australia",
      },
      {
        title: "China",
        value: "China",
      },
      {
        title: "Fiji",
        value: "Fiji",
      },
      {
        title: "Hong Kong",
        value: "Hong Kong",
      },
      {
        title: "India",
        value: "India",
      },
      {
        title: "Indonesia",
        value: "Indonesia",
      },
      {
        title: "Japan",
        value: "Japan",
      },
      {
        title: "Macau",
        value: "Macau",
      },
      {
        title: "Malaysia",
        value: "Malaysia",
      },
      {
        title: "Maldives",
        value: "Maldives",
      },
      {
        title: "New Zealand",
        value: "New Zealand",
      },
      {
        title: "Singapore",
        value: "Singapore",
      },
      {
        title: "South Korea",
        value: "South Korea",
      },
      {
        title: "Taiwan",
        value: "Taiwan",
      },
      {
        title: "Thailand",
        value: "Thailand",
      },
    ],
  },
];

import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchRoute } from "./supabaseClient";

const UserContext = createContext();

// Role Types:
// ContentCatalog.View (Standard)
// ContentCatalog.Export (Standard with Export Privileges)
// ContentCatalog.Edit (ADMIN: can edit/delete anything AND export)

export const regionMapping = {
  NAM: "North America",
  LAC: "Latin America & Caribbean",
  EU: "Europe",
  MEA: "Middle East & Africa",
  AP: "Asia Pacific",
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    role: "",
    firstName: "",
    lastName: "",
    email: "",
    sessionToken: "",
    id: "",
  });
  const [languages, setLanguages] = useState([]);
  const [countriesByRegion, setCountriesByRegion] = useState(null);
  const [sponsorships, setSponsorships] = useState([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        let data = await fetchRoute(
          "unique_languages?select=language",
          user.sessionToken
        );
        let languageObjects = await data.json();

        const languages = languageObjects.map((l) => ({
          label: l.language,
          value: l.language,
        }));
        console.log("Setting languages to ", languages);
        setLanguages(languages);
      } catch (error) {
        console.error(`Error fetching languages: ${error.message}`);
      }
    };

    const fetchSponsorships = async () => {
      try {
        let data = await fetchRoute(
          "unique_sponsorships?select=sponsor",
          user.sessionToken
        );
        let sponsorObjects = await data.json();

        const sponsorships = sponsorObjects.map((l) => ({
          label: l.sponsor,
          value: l.sponsor,
        }));
        console.log("Setting sponsorships to ", sponsorships);
        setSponsorships(sponsorships);
      } catch (error) {
        console.error(`Error fetching sponsorships: ${error.message}`);
      }
    };

    const fetchCountries = async () => {
      try {
        let countries = await fetchRoute("unique_countries", user.sessionToken);
        let data = await countries.json();

        console.log("countries are ", data);

        const hierarchicalData = Object.keys(regionMapping).map(
          (regionCode) => {
            return {
              title: regionMapping[regionCode],
              value: regionMapping[regionCode],
              children: data
                .filter((row) => row.region === regionCode)
                .map((countryRow) => ({
                  title: countryRow.country,
                  value: countryRow.country,
                })),
            };
          }
        );
        setCountriesByRegion(hierarchicalData);
      } catch (error) {
        console.error(`Error fetching countries: ${error.message}`);
      }
    };

    if (user.id) {
      fetchLanguages();
      fetchCountries();
      fetchSponsorships();
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{ user, setUser, languages, countriesByRegion, sponsorships }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Define a custom hook for accessing the context easily
export const useUser = () => useContext(UserContext);

export const isAdmin = (user) => {
  return user.role.includes("ContentCatalog.Edit");
};

export const hasExportPrivileges = (user) => {
  return user.role.includes("ContentCatalog.Export");
};
